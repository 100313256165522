import httpService from "../utils/httpService";

class ChatService {
  constructor() {
    this.abortController = null; // Initialize abortController in the constructor
  }

  getChats = async ({ productId,search_term, page, customer_id, filters }) => {
    // If there's an ongoing request, abort it
    if (this.abortController) {
      this.abortController.abort();
    }
  
    // Create a new AbortController for the current request
    this.abortController = new AbortController();
    const { signal } = this.abortController;
  
    const endpoint = `${productId}/conversations`;
    try {
      const response = await httpService.post(
        endpoint, 
        filters,  // Request body
      { search_term, page, customer_id }, {signal} // Query parameters with signal
      );
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        throw error;
      }
    }
  };

  // Method to get chat data
  getChatData = async ({ chatID, productId }) => {
    const endpoint = `${productId}/conversations/${chatID}/chat-logs`;
    try {
      const response = await httpService.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  };

  
}

// Create an instance of the ChatService
const chatService = new ChatService();
export default chatService;
