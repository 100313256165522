import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import NavBadge from "./NavBadge";
import NavIcon from './NavIcon';
import { AuthContext } from "../../../../context";

class NavContent extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  renderItem = (navigation) => {
    if (!navigation) return null;    

    const { logout } = this.context;
  
    return navigation.map((item) => {
      switch (item.type) {
        case "group":
          return (
            <React.Fragment key={item.id}>
              <Menu.Item className={`menu-item-header ${item.className || ""}`} key={item.id}>
                {item.title}
              </Menu.Item>
              {this.renderItem(item.children)}
            </React.Fragment>
          );
        case "collapse":
          return (
            <Menu.SubMenu
              key={item?.id}
              title={item?.title}
              icon={
                item?.icon
                  ? item?.icon(
                      window.sessionStorage.getItem("color_theam")
                    )
                  : ""
              }
            >
              <Menu.ItemGroup key={item?.id + "1"}>
                {this.renderItem(item?.children)}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          );
        case "item":
          if (item.id === "logout") {
            return (
               <Menu.Item key={item.id} onClick={logout}>
               <NavLink to={item.url} className="nav-link" exact>
                 <NavIcon items={item} />
                 <span className="label">{item.title}</span>
                 <NavBadge items={item} />
               </NavLink>
             </Menu.Item>
            );
          }
          if (item.target) {
            return (
              <Menu.Item key={item.id} className="catlog">
                <a href={item.url} className="nav-link" target="_blank" rel="noreferrer">
                  <NavIcon items={item} />
                  <span className="label">{item.title}</span>
                  <NavBadge items={item} />
                </a>
              </Menu.Item>
            );
          }
          return (
            <Menu.Item key={item.id}>
              <NavLink to={item.url} className="nav-link" exact>
                <NavIcon items={item} />
                <span className="label">{item.title}</span>
                <NavBadge items={item} />
              </NavLink>
            </Menu.Item>
          );
        default:
          return null;
      }
    });
  };
  

  render() {
    const mainContent = (
      <div className="navbar-content datta-scroll">
        <Menu
          theme="light"
          mode="inline"
          onOpenChange={this.onOpenChange}
        >
          {this.renderItem(this.props.navigation)}
        </Menu>
      </div>
    );

    return <div>{mainContent}</div>;
  }
}

export default NavContent;
