import httpService from "../utils/httpService";

class OverviewService {
  
    // Method to get overview data
    getOverviewData = async (productId, bodyParams) => {
      const endpoint = `${productId}/overview-data`;
      try {
        const response = await httpService.post(endpoint, bodyParams);
        return response;
      } catch (error) {
        throw error;
      }
    };
  
  }
  
  // Create an instance of the OverviewService
  const overviewService = new OverviewService();
  export default overviewService;