import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Input,
  Avatar,
  Typography,
  Card,
  Button,
  Modal,
  Tabs,
  Space,
} from "antd";
import {filterIcon, searchIcon } from "../../../data/homeData";
import profile from "../../images/history/profile.png";
import noChats from "../../images/noChats.svg";
import { marked } from "marked";
import Loader from "../../loader";
import DummyChatTemplate from "../../DummyChatTemplate";
import { CloseOutlined } from "@ant-design/icons";
import DebounceSelect from "../../Debounce";
import chatService from "../../../services/chat-service";
import { useLocation } from "react-router-dom";

const { Title } = Typography;
let activeFilters = {
  category: [],
  topic: [],
  sentiment: [],
};

const ChatHistory = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [desktop, setDesktop] = useState(window.innerWidth > 1200);
  const [localLoading, setLocalLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [chats, setChats] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [customer_id, setCustomerId] = useState(null);
  const token = localStorage.getItem("userToken");
  const [clear, setClear] = useState(false);
  const [chatDetails, setChatDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [morePages, setMorePages] = useState(false)
  const FilterMenu = ["By Topic", "By Sentiment", "By Category"];
  const [filters, setFilters] = useState({
    category: [],
    topic: [],
    sentiment: [],
  });
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);
  const productId = getProductIdFromLocalStorage();
  const path = useLocation();
  const { pathname, search } = path;

  async function fetchSentimentOptions(value) {
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/filter-data/sentiment`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      return Object.keys(data.data).map((key) => ({
        label: String(data.data[key]),
        value: key,
      }));
    } catch (error) {
      console.error("Error fetching OS options:", error);
      return [];
    }
  }
  async function fetchTopicOptions(value) {
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/filter-data/topic`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      return Object.keys(data.data).map((key) => ({
        label: String(data.data[key]),
        value: key,
      }));
    } catch (error) {
      console.error("Error fetching topic options:", error);
      return [];
    }
  }
  async function fetchCategoryOptions() {
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/filter-data/category`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      return Object.keys(data.data).map((key) => ({
        label: String(data.data[key]),
        value: key,
      }));
    } catch (error) {
      console.error("Error fetching category options:", error);
      return [];
    }
  }

  const handleTopicChange = (value) => {
    const selectedValues = value.map((item) => item.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      topic: selectedValues,
    }));
  };

  const handleSentimentChange = (value) => {
    const selectedValues = value.map((item) => item.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      sentiment: selectedValues,
    }));
  };

  const handleCategoryChange = (value) => {
    const selectedValues = value.map((item) => item.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: selectedValues,
    }));
  };

  useEffect(()=> {
    activeFilters = {
      category: [],
      topic: [],
      sentiment: [],
    };
  },[productId])
  useEffect(() => {
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    const urlCategory = getUrlParameter("category").split("|").filter(Boolean);
    const urlCustomerId = getUrlParameter("customer_id")
      .split("|")
      .filter(Boolean);
    const urlTopic = getUrlParameter("topic").split("|").filter(Boolean);
    const urlSentiment = getUrlParameter("sentiment")
      .split("|")
      .filter(Boolean);
    if (urlCustomerId.length>0) {      
      setCustomerId(urlCustomerId);
    }
    if(urlCategory.length>0 || urlSentiment.length>0 || urlTopic.length>0)
        {
          const initialFilters = {
            category: urlCategory,
            topic: urlTopic,
            sentiment: urlSentiment,
          };
          console.log(initialFilters);
          
          activeFilters = initialFilters;
          setFilters(initialFilters);
        }
  },[productId]);
 
  const [chatData, setChatData] = useState(null);
  const [error, setError] = useState(null);
  const [chatError, setChatError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChats = async () => {
    try {
      if(searchText == "" && page == 1){
        setIsFetching(true);        
      }
      const filters = {
        category: activeFilters.category.length
          ? activeFilters.category.join("|")
          : undefined,
        topic: activeFilters.topic.length
          ? activeFilters.topic.join("|")
          : undefined,
        sentiment: activeFilters.sentiment.length
          ? activeFilters.sentiment.join("|")
          : undefined,
      };

      const response = await chatService.getChats({
        search_term: searchText,
        page: page,
        productId: productId,
        customer_id: customer_id,
        filters: filters,
      });
      setMorePages(response?.has_more_pages)
      setChatData(response?.data);
      setError(null); // Clear any previous errors
    } catch (err) {
      setError(err);
      setChatData(null); // Clear chat data on error
    } 
    finally{
      setIsFetching(false);
    }

  };

  const fetchChatDetails = async () => {
    setLocalLoading(true);
    try {
      const response = await chatService.getChatData({
        chatID: selectedItem.id,
        productId: productId,
      });
      setChatDetails(response?.data);
      setChatError(null); // Clear any previous errors
    } catch (err) {
      setChatError(err);
      setChatDetails(null); // Clear chat details on error
    }
    finally{
      setLocalLoading(false);
    }
  };

  useEffect(() => fetchChats(), [ customer_id,activeFilters,searchText]);
  useEffect(()=>{
    if((selectedItem?.name !== "" && selectedItem?.id!== 0) || selectedItem !=null ){
      fetchChatDetails();
    }
  },[selectedItem])



  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (((selectedItem?.name !== "" && selectedItem?.id!== 0) || selectedItem !=null )) {
      if (desktop) setSelectedItem(chats[0]);
      else if (!desktop) setSelectedItem(null);
    } else {
      if (desktop) {
        setSelectedItem({
          name: "",
          email: "",
          ended_at: "",
          id: 0,
          ended_at_human: "",
        });
      } else {
        setSelectedItem(null);
      }
    }
  }, [chats, desktop]);

  useEffect(() => {
    if (chatData && page === 1) {
      setChats(chatData);
    } else if (chatData) {
      setChats((prevChats) => [...prevChats, ...chatData]);
    }
  }, [chatData]);

  const handleResize = () => {
    setDesktop(window.innerWidth > 1200);
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setPage(1);
    setChats([]);
    setChatDetails(null);
  };

  const handleClickItem = (item) => {
    setSelectedItem(item);
  };

  const handleBack = () => {
    setSelectedItem(null);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && morePages) {
      setIsFetching(true);
      setPage((prevPage) => prevPage + 1);
      fetchChats();
    }
  };

  const updateUrlWithFilters = (filters) => {
    const params = new URLSearchParams(search);

    Object.keys(filters).forEach((key) => {
      if (filters[key].length > 0) {
        params.set(key, filters[key].join("|"));
      } else {
        params.delete(key);
      }
    });

    const newUrl = `${pathname}?${params.toString()}`;
    window.history.pushState(null, "", newUrl);
  };

  useEffect(() => {
    if (clear) {
      setClear(false);
      activeFilters = filters;
      updateUrlWithFilters(filters);
    }
  }, [clear]);

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setClear(true);
      setOpen(false);
      setConfirmLoading(false);
      setPage(1);
    }, 1000);
  };

  const handleCancel = () => {
    setOpen(false);
    setFilters(activeFilters);
  };
  const handleClearFilters = () => {
    setFilters({
      category: [],
      topic: [],
      sentiment: [],
    });
    setClear(true);
    setOpen(false);
  };

  const ScreenTwo = () => (
    <div>
      <Space
        style={{
          width: "100%",
        }}
        direction="vertical"
      >
        <DebounceSelect
          mode="multiple"
          value={filters.sentiment}
          placeholder="Please select Sentiment"
          fetchOptions={fetchSentimentOptions}
          onChange={handleSentimentChange}
          style={{
            width: "100%",
          }}
        />
      </Space>
    </div>
  );

  const ScreenOne = () => (
    <div>
      <Space
        style={{
          width: "100%",
        }}
        direction="vertical"
      >
        <DebounceSelect
          mode="multiple"
          value={filters.topic}
          placeholder="Please select Topic"
          fetchOptions={fetchTopicOptions}
          onChange={handleTopicChange}
          style={{
            width: "100%",
          }}
        />
      </Space>
    </div>
  );

  const ScreenThree = () => (
    <div>
      <Space
        style={{
          width: "100%",
        }}
        direction="vertical"
      >
        <DebounceSelect
          mode="multiple"
          value={filters.category}
          placeholder="Please select Category"
          fetchOptions={fetchCategoryOptions}
          onChange={handleCategoryChange}
          style={{
            width: "100%",
          }}
        />
      </Space>
    </div>
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleclear = (filterType, label) => {
    setClear(true);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== label),
    }));
  };

  if (isFetching) return <Loader className="loader" />;
  if (isLoading && page === 1) return <Loader className="loader" />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Row
      gutter={[24, 0]}
      className="orderlistFilterContainer"
      justify={"space-around"}
    >
      <Modal
        title="Filters"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" onClick={handleClearFilters}>
            Clear Filters
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            Apply Filters
          </Button>,
        ]}
        confirmLoading={confirmLoading}
      >
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          items={FilterMenu.map((value, i) => {
            const id = String(i + 1);
            return {
              key: id,
              label: `${value}`,
              children:
                id === "1" ? (
                  <ScreenOne />
                ) : id === "2" ? (
                  <ScreenTwo />
                ) : (
                  <ScreenThree />
                ),
            };
          })}
        />
      </Modal>
      {selectedItem === null || desktop ? (
        <>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="h-100">
            <Row gutter={[24, 0]} className="m-2 mb-3 justify-content-between">
              <Col
                xs={16}
                sm={16}
                md={16}
                lg={16}
                xl={16}
                xxl={18}
                className="align-items-center justify-content-start d-flex p-0 "
              >
                <div className="searchBoxContainer m-0 w-100">
                  <Input
                    className="searchBoxInput"
                    placeholder="Search"
                    size="small"
                    suffix={searchIcon}
                    value={searchText}
                    onChange={handleInputChange}
                    allowClear
                  />
                </div>
              </Col>
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="align-items-center justify-content-end d-flex p-0 "
              >
                <Button type="primary outline" onClick={showModal}>
                  {filterIcon}
                  Filters
                  {activeFilters?.category.length > 0 ||
                  activeFilters?.topic.length > 0 ||
                  activeFilters?.sentiment.length > 0 ? (
                    <span class="position-absolute top-0 start-100 translate-middle p-2 bg-primary border border-light rounded-circle"></span>
                  ) : (
                    ""
                  )}
                </Button>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              {(activeFilters.category ||
                activeFilters.sentiment ||
                activeFilters.topic) && (
                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={20}
                  xl={20}
                  xxl={20}
                  className="p-1"
                >
                  {activeFilters.topic &&
                    activeFilters.topic.map((label, index) => (
                      <Button
                        type="primary outline"
                        className="ml-0 ms-3 mb-3"
                        onClick={() => handleclear("topic", label)}
                      >
                        Topic - {label}
                        <CloseOutlined className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle closeIcon" />
                      </Button>
                    ))}

                  {activeFilters.sentiment &&
                    activeFilters.sentiment.map((label, index) => (
                      <Button
                        type="primary outline"
                        className="ml-0 ms-3 mb-3"
                        onClick={() => handleclear("sentiment", label)}
                      >
                        Sentiment - {label}
                        <CloseOutlined className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle closeIcon" />
                      </Button>
                    ))}
                  {activeFilters.category &&
                    activeFilters.category.map((label, index) => (
                      <Button
                        type="primary outline"
                        className="ml-0 ms-3 mb-3"
                        onClick={() => handleclear("category", label)}
                      >
                        Category - {label}
                        <CloseOutlined className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle closeIcon" />
                      </Button>
                    ))}
                </Col>
              )}
            </Row>
            <div className="overflowConversation" onScroll={handleScroll}>
              {((selectedItem?.name !== "" && selectedItem?.id!== 0) || selectedItem !=null ) > 0 ? (
                chats?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleClickItem(item)}
                    className={`historyCard hoverEffect2 ${
                      selectedItem?.id === item.id ? "historyCardSelected" : ""
                    }`}
                  >
                    <Avatar src={profile} className="historyProfileImage" />
                    <div className="nameEmail">
                      <Title level={5}>
                        {item?.name ? (
                          item?.name
                        ) : (
                          <span className="text-mute">
                            <i>Anonymous</i>
                          </span>
                        )}
                      </Title>
                      <p className="emailFont">
                        {item?.email ? item?.email : <> &#8203; </>}
                      </p>
                    </div>
                    <p className="timeAfterEmail">
                      {item?.ended_at ? item?.ended_at_human : <> &#8203; </>}
                    </p>
                  </div>
                ))
              ) : (
                <Title level={5} style={{ textAlign: "center" }}>
                  No Results Found{" "}
                </Title>
              )}
            </div>
            { <Loader />}
          </Col>
          {((selectedItem?.name !== "" && selectedItem?.id!== 0) || selectedItem !=null ) && desktop && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={16}
              xxl={16}
              className="p-0"
            >
              {localLoading ? (
                <Loader />
              ) : (
                <Card className="item-history-card">
                  <ItemDetails
                    title={selectedItem}
                    item={chatDetails}
                    handleBack={handleBack}
                    localLoading={localLoading}
                    desktop={desktop}
                    chats={chats}
                    selectedItem= {selectedItem}
                  />
                </Card>
              )}
            </Col>
          )}
        </>
      ) : (
        ((selectedItem?.name !== "" && selectedItem?.id!== 0) || selectedItem !=null ) &&
        !desktop && (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0">
            {!localLoading ? (
              <ItemDetails
                title={selectedItem}
                item={chatDetails}
                handleBack={handleBack}
                localLoading={localLoading}
                desktop={desktop}
                chats={chats}
                selectedItem= {selectedItem}
              />
            ) : (
              <Loader />
            )}
          </Col>
        )
      )}
    </Row>
  );  
};

const getMarkdownText = (markdown) => {
  const rawMarkup = marked.parse(markdown);
  return { __html: rawMarkup };
};

const ItemDetails = ({ title, item, handleBack, desktop, chats }) => {  
  return (
    <>
      <div className={`openHistoryHeader`}>
        {chats.length > 0 ? (
          desktop ? (
            <>
              <Avatar src={profile} className="historyProfileImage" />
              <div className="nameEmail">
                <Title level={5} className="">
                  {title?.name ? title?.name : "Anonymous"}
                </Title>
                <p className="emailFont">
                  {title?.email ? title?.email : <> &#8203; </>}
                </p>
              </div>
              <p className="timeAfterEmail">
                {title?.ended_at ? title?.ended_at_human : <> &#8203; </>}
              </p>
            </>
          ) : (
            <>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleBack}
              >
                <path
                  d="M11.2499 14.9401L6.35988 10.0501C5.78238 9.47256 5.78238 8.52756 6.35988 7.95006L11.2499 3.06006"
                  stroke="#292D32"
                  strokeWidth="1.125"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Title level={5} className="ms-2">
                {title?.name ? title?.name : "Anonymous"}
              </Title>
            </>
          )
        ) : !desktop ? (
          <div className="dummyHeader">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleBack}
            >
              <path
                d="M11.2499 14.9401L6.35988 10.0501C5.78238 9.47256 5.78238 8.52756 6.35988 7.95006L11.2499 3.06006"
                stroke="#292D32"
                strokeWidth="1.125"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <div className="dummyHeader" />
        )}
      </div>
      {item !== null ? (
        <div className="chatHistoryWrapper">
          <div className={"chatContainer"}>
            {item?.length > 0 ? (
              item?.map((prompt, index) => (
                <div key={index} className="questionContainer">
                  <div
                    className="itemQuestion"
                    dangerouslySetInnerHTML={getMarkdownText(prompt.user)}
                  />
                  <div
                    className="itemAnswer"
                    dangerouslySetInnerHTML={getMarkdownText(prompt.assistant)}
                  />
                </div>
              ))
            ) : (
              <DummyChatTemplate />
            )}
          </div>
        </div>
      ) : (
        <div className="noChatData">
          <img alt="no chats available" src={noChats} />
          <h2 className="noChatHead">No Data Found</h2>
        </div>
      )}
    </>
  );
};

export default ChatHistory;
