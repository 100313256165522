import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Row,
  Col,
  Avatar,
  Typography,
  Card,
  Space,
  Modal,
  Button,
  Tabs,
  DatePicker
} from "antd";

import ReactDOM from 'react-dom';
import { filterIcon } from "../../../data/homeData";
import profile from "../../images/history/profile.png";
import noChats from "../../images/noChats.svg";
import { marked } from "marked";
import Loader from "../../loader";
import DebounceSelect from "../../Debounce";
import "datatables.net";
import DummyChatTemplate from "../../DummyChatTemplate";
import $ from "jquery";
import chatService from "../../../services/chat-service";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { RangePicker } = DatePicker;
let filterData = [];

const ChatDetails = () => {
  const [desktop, setDesktop] = useState(window.innerWidth > 1200);
  const desktopRef = useRef(desktop);
  const [localLoading, setLocalLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const token = localStorage.getItem("userToken");
  const [selectedItem, setSelectedItem] = useState(null);
  const [chatDetails, setChatDetails] = useState(null);
  const [Productselected, setSelectedProduct] = useState([]);
  const [currentSelectedProduct, setcurrentSelectedProduct] = useState([]);
  const selectedFilter = "Filters";
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  // const [filterActive, setfilterActive] = useState(false);
  const tableRef = useRef(null);
  const [chatData, setChatData] = useState(null);
  const [error, setError] = useState(null);

  const [displayDateRange, setDisplayDateRange] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [summaryData, setSummaryData] = useState(null);

  const table = $(tableRef.current).DataTable();

  const history = useHistory();

  const FilterMenu = ["By Product"];

  useEffect(() => {
    if (!token)
      history.push("./signin");
  }, [])

  useEffect(() => {
    desktopRef.current = desktop;  // Update desktopRef with the current desktop value
  }, [desktop]);

  function removeSelectedRowClass() {
    const selectedChatRow = document.querySelector('.selected-row');
    if (selectedChatRow) {
      selectedChatRow.classList.remove('selected-row');
    }
  }

  const fetchChatDetails = async (item, rowData) => {
    setLocalLoading(true);
    setSelectedItem({
      name: rowData.name,
      email: rowData.email,
      id: rowData.id,
    });
    try {
      const response = await chatService.getChatData({
        chatID: item.chat_id,
        productId: item.product_id,
      });
      setChatDetails(response?.data);
      setError(null);
    } catch (err) {
      setError(err);
      setChatDetails(null);
    }
    finally {
      setLocalLoading(false);
    }
  };


  const handleClearFilters = () => {
    setcurrentSelectedProduct([]);
    setSelectedProduct([]);
    setOpen(false);
    // setfilterActive(false);
    table.column(3).search("", true, false).draw();
  }


  async function fetchProductOptions(value) {
    const url = `${process.env.REACT_APP_BASE_URL}/product-options`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      filterData = data.map((item) => ({
        label: String(item.name),
        value: item.id,
      }));
      return data.map((item) => ({
        label: String(item.name),
        value: item.name.split(" ")[0].toLowerCase(),
      }));
    } catch (error) {
      console.error("Error fetching topic options:", error);
      return [];
    }
  }

  const handleProductChange = (value) => {
    const selectedValues = value;
    setcurrentSelectedProduct(selectedValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const ScreenOne = () => (
    <div>
      <Space
        style={{
          width: "100%",
        }}
        direction="vertical"
      >
        <DebounceSelect
          mode="single"
          value={currentSelectedProduct}
          placeholder="Please select Product"
          fetchOptions={fetchProductOptions}
          onChange={handleProductChange}
          style={{
            width: "100%",
          }}
        />
      </Space>
    </div>
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setSelectedProduct(currentSelectedProduct);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    });
    const searchValue = (filterData.find((item) =>
      currentSelectedProduct.value === item.label.split(" ")[0].toLowerCase()
    ) || {}).value || "";
    table.column(3).search(searchValue, true, false).draw();
  };


  const handleDateChange = (dates) => {
    setDisplayDateRange(dates);
  };

  // const handleCalendarChange = (dates) => {
  //   console.log("Calendar Dates Changed:", dates);
  // };

  const handleOpenChange = (open) => {
    setIsCalendarOpen(true);
  };

  const handleCalendarCancel = () => {
    setDateRange({
      start: null,
      end: null,
    });
    table.column(2).search("", true, false).draw();
    setIsCalendarOpen(false);
  };

  const handleCalendarSubmit = () => {
    const validateDates = (dates) => {
      if (!dates || dates.length !== 2) {
        return false;
      }
      return true;
    }

    if (validateDates(displayDateRange)) {
      const startDate = displayDateRange[0].format("YYYY-MM-DD");
      const endDate = displayDateRange[1].format("YYYY-MM-DD");

      setDateRange({
        start: startDate,
        end: endDate
      })

      table.column(2).search(JSON.stringify({
        start: startDate,
        end: endDate
      }), true, false).draw();
      setIsCalendarOpen(false);
    } else {
      console.error("Invalid date range selected");
      setIsCalendarOpen(false);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.relatedTarget)) {
        setIsCalendarOpen(false);
      }
    };

    if (isCalendarOpen) {
      calendarRef.current?.addEventListener('blur', handleClickOutside, true);
    }

    return () => {
      calendarRef.current?.removeEventListener('blur', handleClickOutside, true);
    };
  }, [isCalendarOpen]);


  useEffect(() => {
    const fetchSummaryData = async () => {
      setDataLoading(true);
      const product_id = (filterData.find((item) =>
        Productselected.value === item.label.split(" ")[0].toLowerCase()
      ) || {}).value || "";
      setError(false);
      const payload = {
        start: dateRange.start || "",
        end: dateRange.end || "",
        product_id: product_id
      };

      const url = `${process.env.REACT_APP_BASE_URL}/summaryBoxes-data`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        const data = result.data || {};
        setSummaryData(data);
      } catch (error) {
        setError("Failed to fetch summary data.");
        console.error("Fetch Error:", error);
      } finally {
        setDataLoading(false);
      }

    };

    fetchSummaryData()
  }, [dateRange, Productselected])

  const getDynamicTitle = (title) => {
    if (title === "Sessions") return summaryData?.total_sessions?.toString() || "";
    if (title === "Users") return summaryData?.known_users?.toString() || "";
    if (title === "Chats") return summaryData?.total_chats?.toString() || "";
    return "";
  };

  const staticData = [
    {
      title: "Sessions",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
            fill="white"
          />
          <path
            d="M20.7896 14.6999C19.6696 15.4499 18.0996 15.7299 16.6496 15.5399C17.0296 14.7199 17.2296 13.8099 17.2396 12.8499C17.2396 11.8499 17.0196 10.8999 16.5996 10.0699C18.0796 9.86991 19.6496 10.1499 20.7796 10.8999C22.3596 11.9399 22.3596 13.6499 20.7896 14.6999Z"
            fill="white"
          />
          <path
            d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
            fill="white"
          />
          <path
            d="M6.54914 12.8501C6.54914 13.8201 6.75914 14.7401 7.13914 15.5701C5.72914 15.7201 4.25914 15.4201 3.17914 14.7101C1.59914 13.6601 1.59914 11.9501 3.17914 10.9001C4.24914 10.1801 5.75914 9.8901 7.17914 10.0501C6.76914 10.8901 6.54914 11.8401 6.54914 12.8501Z"
            fill="white"
          />
          <path
            d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
            fill="white"
          />
          <path
            d="M8.87078 17.9401C7.36078 18.9501 7.36078 20.6101 8.87078 21.6101C10.5908 22.7601 13.4108 22.7601 15.1308 21.6101C16.6408 20.6001 16.6408 18.9401 15.1308 17.9401C13.4208 16.7901 10.6008 16.7901 8.87078 17.9401Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "Users",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.1391 2.96004L7.10914 5.96004C1.03914 7.99004 1.03914 11.3 7.10914 13.32L9.78914 14.21L10.6791 16.89C12.6991 22.96 16.0191 22.96 18.0391 16.89L21.0491 7.87004C22.3891 3.82004 20.1891 1.61004 16.1391 2.96004ZM16.4591 8.34004L12.6591 12.16C12.5091 12.31 12.3191 12.38 12.1291 12.38C11.9391 12.38 11.7491 12.31 11.5991 12.16C11.3091 11.87 11.3091 11.39 11.5991 11.1L15.3991 7.28004C15.6891 6.99004 16.1691 6.99004 16.4591 7.28004C16.7491 7.57004 16.7491 8.05004 16.4591 8.34004Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "Chats",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 7.52V13.4C21 13.74 20.67 13.98 20.35 13.88L16.42 12.66C15.34 12.33 14.18 12.61 13.39 13.4C12.59 14.2 12.3 15.37 12.64 16.45L13.85 20.35C13.95 20.67 13.71 21 13.37 21H7.52C4.07 21 2 18.94 2 15.48V7.52C2 4.06 4.07 2 7.52 2H15.48C18.93 2 21 4.06 21 7.52Z"
            fill="white"
          />
          <path
            d="M21.9597 18.84L20.3297 19.39C19.8797 19.54 19.5197 19.89 19.3697 20.35L18.8197 21.98C18.3497 23.39 16.3697 23.36 15.9297 21.95L14.0797 16C13.7197 14.78 14.8197 13.68 16.0397 14.04L22.0097 15.9C23.4097 16.34 23.4297 18.32 21.9597 18.84Z"
            fill="white"
          />
        </svg>
      ),
    },
  ]

  useEffect(() => {
    if (!tableRef.current) return;

    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/conversations-data`;
    try {
      const table = $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        response: true,
        search: {
          return: true
        },
        dom: "<'row justify-content-between align-item-center'<'col-auto col-md-3 col-lg-3 mb-2 'l><'col-md-9 col-sm-12 col-12'<'row justify-content-end align-item-center'<'col-xxl-8 col-xl-8 col-lg-8 col-sm-12 col-md-12 mb-2'bf>>>><'col-auto overflow-auto customeTableWrapper't><'footerContainer overflow-auto'<'dt-layout-cell dt-start'i><'dt-layout-cell dt-end'p>>",
        "language": {
          "search": '<div class="customSearchSvg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.25 15C11.9779 15 15 11.9779 15 8.25C15 4.52208 11.9779 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.9779 4.52208 15 8.25 15Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.1973 15.5174C14.5948 16.7174 15.5023 16.8374 16.1998 15.7874C16.8373 14.8274 16.4173 14.0399 15.2623 14.0399C14.4073 14.0324 13.9273 14.6999 14.1973 15.5174Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          "searchPlaceholder": "Press Enter To Search",
        },
        ajax: function (data, callback) {
          $.ajax({
            url: url,
            type: 'POST',
            body: [],
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: data,
            success: function (response) {
              callback(response);
              const firstRowData = table.row(0).data();
              if (firstRowData != null && desktop) {
                const item = {
                  product_id: firstRowData.product_id,
                  chat_id: firstRowData.id
                };
                fetchChatDetails(item, firstRowData);
              } else {
                setChatDetails(null);
              }

              $('.action-btn').on('click', function () {
                if (!desktopRef.current) {
                  $('.custom-row').addClass('d-none');
                }
                const id = $(this).data('id');
                const rowData = table.row($(this).parents('tr')).data();
                const item = {
                  product_id: rowData.product_id,
                  chat_id: rowData.id
                };
                fetchChatDetails(item, rowData);
              });
            },

            error: function (xhr, status, error) {
              if (status !== 'abort') {
                console.log('Error:', error);
                setIsError(error.message);
              }
            }
          });
        },
        order: [2, "desc"],
        columns: [
          { data: "name", title: "Name" },
          { data: "email", title: "Email" },
          { data: "created_at", title: "Created at", orderSequence: ["desc", "asc"] },
          { data: "product_name", title: "Product" },
          {
            data: "id",
            title: "Action",
            render: function (data, type, row) {
              return `<a data-id="${row.id}" class="action-btn">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5799 12C15.5799 13.98 13.9799 15.58 11.9999 15.58C10.0199 15.58 8.41992 13.98 8.41992 12C8.41992 10.02 10.0199 8.42004 11.9999 8.42004C13.9799 8.42004 15.5799 10.02 15.5799 12Z" stroke="#5889E0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" stroke="#5889E0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </a>`;
            },
          },
        ],
        drawCallback: function (settings) {
          const firstRow = table.row(0);
          const isDesktop = window.innerWidth >= 1200;
          if (isDesktop && firstRow.data() != null) {
            $(firstRow.node()).addClass('selected-row');
          }
        }
      });

      // Enable click event for entire row to trigger chat window
      $(tableRef.current).on('click', 'tbody tr', function () {
        if (!desktopRef.current) {
          $('.custom-row').addClass('d-none');
        }
        if (!$(this).hasClass('selected-row')) {
          $(this).siblings().removeClass('selected-row');          // Remove class from other rows
          $(this).addClass('selected-row');                        // Add class to the clicked row
          const rowData = table.row(this).data();
          const item = {
            product_id: rowData.product_id,
            chat_id: rowData.id
          };
          fetchChatDetails(item, rowData);
        }
      });

      // Apply CSS for pointer cursor to all rows
      $(tableRef.current).on('mouseenter', 'tbody tr', function () {
        $(this).css('cursor', 'pointer');
      });

      $('input[type="search"]').on('keypress', function (e) {
        if (e.which === 13) {
          const searchTerm = $(this).val();
        }
      });

      // Add event listener to search input
      $('input[type="search"]').on('input', function () {
        if ($(this).val() === '') {
          table.search('').draw();
        }
      });

      $('#filter').addClass('customfilter col-xl-2 col-xxl-2');
      $('#summarySection').addClass('w-100');

      // Cleanup on component unmount
      return () => {
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          $(tableRef.current).DataTable().destroy(true);
        }
      };
    } catch (error) {
      console.log("Error:", error);
      setIsError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (desktop) {
      $('.custom-row').removeClass('d-none');
    }
    if (desktop) {
      setChatDetails(null);
      setSelectedItem({
        name: "",
        email: "",
        id: 0,
      });

    } else {
      setSelectedItem(null);
    }
    removeSelectedRowClass();
  }, [desktop]);

  useEffect(() => {
    if (chatData) {
      setChatData(chatData);
    } else if (chatData) {
      setChatData((prevChats) => [...prevChats, ...chatData]);
    }
    setIsFetching(false);
  }, [chatData]);


  const handleResize = () => {
    setDesktop(window.innerWidth > 1200);
  };

  const handleBack = () => {
    setSelectedItem(null);
    if (!desktopRef.current) {
      $('.custom-row').removeClass('d-none');
    }
    removeSelectedRowClass();
  };

  const renderItemDetails = () => (
    <Card className="item-history-card chat-card">
      <ItemDetails
        title={selectedItem}
        item={chatDetails}
        handleBack={handleBack}
        localLoading={localLoading}
        desktop={desktop}
      />
    </Card>
  );


  if (isLoading) return <Loader className="loader" />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="py-4 px-3 px-lg-4">
        <Row className="custom-row dateRange-filter-col" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={8}>
            <h4>Chat Details</h4>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={14} className="d-flex d-grid gap-4 mb-0 header-col-calendar">
            <div className="ant-space ant-space-vertical">
              <div className="ant-space-item" ref={calendarRef} onFocus={() => setIsCalendarOpen(true)} >
                <Space direction="vertical" size={12}>
                  <RangePicker
                    value={displayDateRange}
                    onChange={handleDateChange}
                    // onCalendarChange={handleCalendarChange}
                    onOpenChange={handleOpenChange}
                    open={isCalendarOpen}
                    format="YYYY-MM-DD"
                    renderExtraFooter={() => (
                      <div style={{ textAlign: "right" }}>
                        <Col>
                          <Button
                            type="muted"
                            className="m-2"
                            key={"start"}
                            onClick={handleCalendarCancel}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            className="m-2"
                            key={"end"}
                            onClick={handleCalendarSubmit}
                          >
                            Submit
                          </Button>
                        </Col>
                      </div>
                    )}
                  />
                </Space>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={2} className="fliterButtonCol">
            <Button key={"filterButton"} className="filterButton outline w-100" onClick={showModal}>
              <div className="icon">
                {filterIcon}
              </div>
              {selectedFilter}
              {(Productselected && Productselected.label) && (
              <span className="position-absolute top-0 start-100 translate-middle p-2 bg-primary border border-light rounded-circle">
              <span className="visually-hidden">New alerts</span>
                </span>
              )}
              </Button>
          </Col>
        </Row>

        <Modal
          title="Filters"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
          footer={[
            <Button key="clear" onClick={handleClearFilters}>
              Clear Filters
            </Button>,
            <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
              Apply Filters
            </Button>,
          ]}
        >
          <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            items={FilterMenu.map((value, i) => {
              const id = String(i + 1);
              return {
                key: id,
                label: `${value}`,
                children: <ScreenOne />
              };
            })}
          />
        </Modal>

        {dataLoading ? (
          <Row className="mt-3 custom-row" gutter={[24, 0]}>
            <Col
              xs={24}
            >
              <Loader loader="smallSummaryLoader" />
            </Col>
          </Row>
        ) : (
          <Row className="mt-3 custom-row" gutter={[24, 0]}>
            {staticData.map((item) => {
              return (
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  xl={8}
                  xxl={8}
                  span={24}
                  className="layout-chat"
                  key={item.title}
                >
                  <Card
                    bordered={false}
                    className={`crm-bar-line header-solid ${item.icon && "dashIconStyles"
                      }`}
                  >
                    <div className={`dashTileFlex satisfaction`}>
                      <div>
                        <h6 className="text-md font-semibold text-muted">
                          {item.title}
                        </h6>
                        <h5 className="mb-0">{getDynamicTitle(item.title)}</h5>
                      </div>
                      {item.icon && (
                        <div className="icon-box icon-box-custom">
                          {item.icon}
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}

        <Row className="chat-table mt-3" gutter={[24, 0]}>
          <Col className="custom-row" xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
            <Card
              bordered={false}
              className="header-solid tableBorder"
            >
              {isError ? (
                <Alert
                  className="m-4 text-center"
                  message={`Error: ${isError}`}
                  type="error"
                />
              ) : (
                <div className="customTableWrapper">
                  <div className="customTable customerTable">
                    <table
                      ref={tableRef}
                      className="table table-responsive"
                      style={{ width: "100%" }}
                    >
                      <thead className="ant-table-thead">
                        <tr className="ant-table-row">
                          <th className="ant-table-cell">Name</th>
                          <th className="ant-table-cell">Email</th>
                          <th className="ant-table-cell">Created at</th>
                          <th className="ant-table-cell">Product</th>
                          <th className="ant-table-cell">Action</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}

              {isFetching && <Loader />}
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
            {selectedItem !== null && (
              localLoading ? (
                <Loader />
              ) : (
                renderItemDetails()
              )
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const getMarkdownText = (markdown) => {
  const rawMarkup = marked.parse(markdown);
  return { __html: rawMarkup };
};

const ItemDetails = ({ title, item, handleBack, desktop }) => {
  return (
    <>
      {(title != null && title.id != 0) &&
        (desktop ? (
          <div className={`openHistoryHeader`}>
            <Avatar src={profile} className="historyProfileImage" />
            <div className="nameEmail">
              <Title level={5} className="">
                {title?.name ? title?.name : "Anonymous"}
              </Title>
              <p className="emailFont">
                {title?.email ? title?.email : <> &#8203; </>}
              </p>
            </div>
            <p className="timeAfterEmail">
              {title?.ended_at ? title?.ended_at_human : <> &#8203; </>}
            </p>
          </div>
        ) : (
          <div className={`openHistoryHeader`}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleBack}
              className="back"
            >
              <path
                d="M11.2499 14.9401L6.35988 10.0501C5.78238 9.47256 5.78238 8.52756 6.35988 7.95006L11.2499 3.06006"
                stroke="#292D32"
                strokeWidth="1.125"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Title level={5} className="ms-2">
              {title?.name ? title?.name : "Anonymous"}
            </Title>
          </div>
        ))}
      {item !== null ? (
          <div className="chatWrapper">
            <div className={"chatContainer chat-logs-container"}>
              {item?.length > 0 ? (
                item?.map((prompt, index) => (
                  <div key={index} className="questionContainer">
                    <div
                      className="itemQuestion"
                      dangerouslySetInnerHTML={getMarkdownText(prompt.user)}
                    />
                    <div
                      className="itemAnswer"
                      dangerouslySetInnerHTML={getMarkdownText(prompt.assistant)}
                    />
                  </div>
                ))
              ) : (
                <DummyChatTemplate />
              )}
            </div>
          </div> 
        ) : (
          <div className="noChatData">
            <img alt="no chats available" src={noChats} />
            <h2 className="noChatHead">No Data Found</h2>
          </div>
        )
      }
    </>
  );
};

export default ChatDetails;
