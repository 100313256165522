import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../data/lottie.json'; 

const Loader = ({loader}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <div className={`loaderContainer ${loader}`} ><Lottie options={defaultOptions} height="100px" width="200px" /></div>;
};

export default Loader;