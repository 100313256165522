import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card, Col, Row, Typography } from "antd";
import Echart from "../chart/Echart";
import CRMChart from "../chart/CRMChart";
import { current, satisfaction } from "../../data/homeData";
import Loader from "../loader";
import overviewService from "../../services/overview-service";
import { DateContext } from "../../context/dateContext";

const { Title } = Typography;

const Home = () => {
  const {date} = useContext(DateContext);

  const [hoverDayIndex, setHoverDayIndex] = useState(null);
  const [hoverTimeIndex, setHoverTimeIndex] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [coloredDayData, setColoredDayData] = useState([]);
  const [coloredHourData, setColoredHourData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);


  const productId = getProductIdFromLocalStorage();
  const fetchData = async () => {
    try {
      const bodyParams = {
        start_date: date[0].format("YYYY-MM-DD"),
        end_date: date[1].format("YYYY-MM-DD"),
      };
      
      const response = await overviewService.getOverviewData(productId, bodyParams);
      setData(response?.data); // Assuming response contains the data you need
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => fetchData(), [productId, date]);

  const itemColour = ["#1890ff", "#FF6384","#FFD363"]
  const colorPalette = ["#5889e0"];
  const dayOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const hourOrder = [
    "12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am",
    "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm"
  ];

  function hexToRgba(hex, opacity) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  function assignColors(data, colorPalette) {
    const dataCopy = JSON.parse(JSON.stringify(data));

    function sortByOrder(array, order, key) {
      return array.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]));
    }

    const sortedDayData = sortByOrder(
      dataCopy.filter(item => item.label && dayOrder.includes(item.label)),
      dayOrder,
      'label'
    );

    const sortedHourData = sortByOrder(
      dataCopy.filter(item => item.hour && hourOrder.includes(item.hour)),
      hourOrder,
      'hour'
    );

    const sortedData = [...sortedDayData, ...sortedHourData];

    const maxUserCount = Math.max(...sortedData.map(item => item.user_count || 0));

    sortedData.forEach((item, index) => {
      if (item.user_count === null || item.user_count === 0 || item.user_count === undefined) {
        item.color = hexToRgba(colorPalette[colorPalette.length - 1], 0.1);
      } else {
        const opacity = 0.3 + (item.user_count / maxUserCount) * 0.7;
        item.color = hexToRgba(colorPalette[Math.min(index, colorPalette.length - 1)], opacity);
      }
    });

    return sortedData;
  }

  useEffect(() => {
    if (data) {
      setOverviewData(data);
    }
  }, [data]);

  useEffect(() => {
    if (overviewData) {
      if (overviewData?.activity_timeline?.day?.data) {
        setColoredDayData(assignColors(overviewData.activity_timeline.day.data, colorPalette));
      }

      if (overviewData?.activity_timeline?.hour?.data) {
        setColoredHourData(assignColors(overviewData.activity_timeline.hour.data, colorPalette));
      }

      setCountData([
        {
          today: "Total QR Code Scans ",
          title: overviewData?.total_users?.count.toString(),
          persent: `+${overviewData?.total_users?.change}%`,
          chart: true,
          data: overviewData?.total_users?.chart
        },
        {
          today: "Total Conversations",
          title: overviewData?.total_conversation?.count.toString(),
          persent: `+${overviewData?.total_conversation?.change}%`,
          chart: true,
          data: overviewData?.total_conversation?.chart
        },
        {
          today: "Chatbot Uptime",
          title: overviewData?.chatbot_uptime?.value,
          chart: true,
          data: overviewData?.chatbot_uptime?.chart
        },
        {
          today: "Customer Satisfaction Score",
          subTitle: overviewData?.customer_satisfaction_score.toString(),
          icon: satisfaction,
          chart: false,
        },
      ]);
    }
  }, [overviewData]); 

  const handleDayEnter = (index) => {
    setHoverDayIndex(index);
  };

  const handleDayLeave = () => {
    setHoverDayIndex(null);
  };

  const handleTimeEnter = (index) => {
    setHoverTimeIndex(index);
  };

  const handleTimeLeave = () => {
    setHoverTimeIndex(null);
  };

  const renderHoverDay = (label, color) => {
    return (
      <div
        style={{
          maxHeight: "max-content",
          maxWidth: "max-content",
          zIndex: 1,
          position: "absolute",
          backgroundColor: "white",
          border: "1px solid rgb(213, 213, 213)",
          borderRadius: "5px",
          pointerEvents: "none",
          display: "block",
          top: "-50%",
          boxShadow: "0 0 10px #00000050",
        }}
      >
        <div>
          <div className="tooltipHeading">Activity</div>
          <div className="tooltipContent">
            <div className="tooltipColor" style={{ backgroundColor: color }} />
            <p>{label}</p>
          </div>
        </div>
      </div>
    );
  };

  const returnHoverTime = (hour, color) => {
    return (
      <div
        style={{
          maxHeight: "max-content",
          maxWidth: "max-content",
          zIndex: 1,
          position: "absolute",
          backgroundColor: "white",
          border: "1px solid rgb(213, 213, 213)",
          borderRadius: "5px",
          pointerEvents: "none",
          display: "block",
          top: "-50%",
          boxShadow: "0 0 10px #00000050",
        }}
      >
        <div>
          <div className="tooltipHeading">Activity</div>
          <div className="tooltipContent">
            <div
              className="tooltipColor"
              style={{ backgroundColor: color, width: "10px", height: "10px" }}
            />
            <p>{hour}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderDay = (item, subIndex) => {
    if (!item.label) return null;
    let label = [item.label.slice(0, 3), item.label.slice(3)];
    return (
      <div key={subIndex} className="flexDays" style={{ position: "relative" }}>
        <span>{label[0]}</span>
        <div
          className="daybox"
          onMouseEnter={() => handleDayEnter(subIndex)}
          onMouseLeave={handleDayLeave}
          style={{
            backgroundColor: item.color,
          }}
        />
        {hoverDayIndex === subIndex && renderHoverDay(item.label, item.color)}
      </div>
    );
  };

  const renderTime = (item, subIndex) => {
    if (!item.hour) return null;

    const totalHoursString = item.hour.replace(/am|pm/i, "");
    const totalHours = parseInt(totalHoursString, 10);
    const isDivisibleByFour = totalHours % 4 === 0;

    return (
      <div key={subIndex} className="flexDays" style={{ position: "relative" }}>
        <span
          className="time"
          style={{
            visibility: isDivisibleByFour ? "visible" : "hidden",
          }}
        >
          {item.hour}
        </span>
        <div
          onMouseEnter={() => handleTimeEnter(subIndex)}
          onMouseLeave={handleTimeLeave}
          className="timeBox"
          style={{
            backgroundColor: item.color,
          }}
        />
        {hoverTimeIndex === subIndex && returnHoverTime(item.hour, item.color)}
      </div>
    );
  };

  return (
    <>
      {overviewData ? (
        <div className="layout-content">
          <Row gutter={[24, 0]}>
            {countData.map((item, index) => (
              <Col
                key={`count-${index}`}
                xs={24}
                md={12}
                xl={12}
                lg={12}
                xxl={6}
                span={24}
                className="mb-24"
              >
                <Card
                  bordered={false}
                  className={`crm-bar-line header-solid h-100 ${item.icon && "dashIconStyles"}`}
                >
                  <div className={`dashTile ${item.subTitle && "dashTileFlex satisfaction"}`}>
                    <div>
                      <h6 className="text-md font-semibold text-muted">{item.today}</h6>
                      {item.subTitle ? (
                        <h1>{item.subTitle}</h1>
                      ) : (
                        <h5 className="mb-0">
                          {item.title} <small className="text-succes">{item.persent}</small>
                        </h5>
                      )}
                    </div>
                    {item.icon && <div className="icon-box">{item.icon}</div>}
                    {item.chart && <CRMChart apiData={item.data} />}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} lg={16} xl={16} xxl={18} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="ant-card-head-title">
                  <Title level={4}>User Insights</Title>
                </div>
                <Echart new={overviewData.user_insights.new} returning={overviewData.user_insights.returning} xKeys={overviewData.user_insights.xKeys} />
              </Card>
            </Col>
            <Col xs={24} sm={24} xl={8} lg={8} xxl={6} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="splitContainer">
                  <Title level={4} className="mb-4">
                    Conversation Split by Type
                  </Title>
                  {overviewData.conversation_split_by_type &&
                    overviewData.conversation_split_by_type.map((item, index) => (
                      <div className="splitProgressContainer mb-4" key={index}>
                        <div className="split_tiles">
                          <p>{item.classification}</p>
                          <p>{item.type_count}</p>
                        </div>
                        <div className="progressDefault">
                          <div
                            style={{
                              width: `${Math.min(item.type_count * 10,100)}%`, //change the value of it according to data count
                              backgroundColor: "#5889E0",
                            }}
                            className="progressDefault"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} sm={24} lg={24} xl={10}>
              <Row gutter={[24, 0]} className="h-full">
                <Col
                  key="current-active-users"
                  xs={24}
                  md={24}
                  sm={24}
                  lg={24}
                  xl={24}
                  className="mb-24"
                >
                  <Card
                    bordered={false}
                    className={`crm-bar-line header-solid dashIconStyles dashIconStyles1`}
                  >
                    <div style={{ width: "100%" }}>
                      <h3 className="text-md font-semibold">Current Active Users</h3>
                      <h1>{overviewData.current_active_users}</h1>
                    </div>
                    <div className="icon-box">{current}</div>
                  </Card>
                </Col>
                <Col xs={24} md={24} sm={24} lg={24} xl={24} className="mb-24">
                  <Card
                    bordered={false}
                    className="criclebox card-info-2 h-full progressDeviceSection"
                  >
                    <div className="ant-muse">
                      <Title level={4} className="mb-4">
                        Sessions By OS
                      </Title>
                      {overviewData.sessions_by_devices &&
                        overviewData.sessions_by_devices.map((item, index) => (
                          <div className="listType" key={index}>
                            <h4>{(item.user_device.toLowerCase()==="ios")?"iOS": item.user_device}</h4>
                            <div className="deviceProgressContainer">
                              <div className="deviceProgress">
                                <div
                                  style={{
                                    width: `${item.percent}%`,
                                    backgroundColor: itemColour[index],
                                  }}
                                  className="deviceProgress"
                                />
                              </div>
                              <Title level={5}>{item.percent}%</Title>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={24} sm={24} lg={24} xl={14} className="mb-24 analysis">
              <Card bordered={false} className="criclebox card-info-2 h-full">
                <Row gutter={[24, 0]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mobile-24">
                    <div className="h-full col-content">
                      <div className="ant-muse">
                        <Title level={4}>Activity Timeline</Title>
                        <div className="headingContainer">
                          <Title level={5} className="maximumHeading">
                            Maximum Activity
                          </Title>
                        </div>
                        <div className="dynamictTime mb-2">
                          <p className="subHeadingDate">{overviewData.activity_timeline?.day?.max || '\u200B'}</p>
                          <p className="subHeadingDate">{overviewData.activity_timeline?.hour?.max || '\u200B'}</p>
                        </div>
                        <div className="mb-4">
                          <Title level={5}>Day</Title>
                          <div className="daysAndTime">
                            {coloredDayData.map((item, subIndex) =>
                              renderDay(item, subIndex)
                            )}
                          </div>
                        </div>
                        <div>
                          <Title level={5}>Time</Title>
                          <div className="daysAndTime timeGrid">
                            {coloredHourData.map((item, subIndex) =>
                              renderTime(item, subIndex)
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Home;
