import httpService from "../utils/httpService";

class ProductService {
  constructor() {
    this.abortController = null; // Initialize abortController in the constructor
  }

  // Method to abort any ongoing request
  abortRequest = () => {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null; // Reset abortController after abort
    }
  };

  getProducts = async (payload) => {
    // Abort any ongoing request
    if (this.abortController) {
      this.abortController.abort();
    }

    // Create a new AbortController for the current request
    this.abortController = new AbortController();
    const { signal } = this.abortController;

    const productEndpoint = 'products';
    
    try {
      const response = await httpService.get(
        productEndpoint,
        payload['params'], // Query parameters
        { signal } // Pass signal as options
      );

      // Reset abortController after successful request
      this.abortController = null;

      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error(error);
      }
      throw error;
    }
  };
}

const productService = new ProductService();
export default productService;
