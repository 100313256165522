import HttpService from "../utils/httpService"

class AuthService {

  login = async (payload) => {
    const loginEndpoint = 'login';    
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'forgot-password';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'reset-password';
    return await HttpService.post(resetPassword, credentials);
  }
}

const authService = new AuthService();
export default authService;