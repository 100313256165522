import React, { useRef,useEffect,useState, useCallback } from "react";
import { Card,Alert } from "antd";
import {} from "@ant-design/icons";
import $ from "jquery";
import "datatables.net";
import Loader from "../../loader";

const Queries = () => {
  const tableRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);

  const productId = getProductIdFromLocalStorage();

  useEffect(() => {
    if (!tableRef.current) return;
    setLoading(true);
    const token = localStorage.getItem("userToken");
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/common-queries`;

    try {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        dom:  "<'overflow-auto't><'footerContainer ps-3 pe-3 overflow-auto'<'dt-layout-cell dt-start'i><'dt-layout-cell dt-end'p>>",
        ajax: {
          url: url,
          type: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        order: [0, "desc"],
        columns: [
          { data: "prompts", title: "Prompts" ,orderable:false},
          {
            data: "category",
            title: "Category",
            orderable:false,
            render: function (e) {
              return (
                e && `<a href="${encodeURI(`/${productId}/analysis/chat-history?category=${e.toString()}`)}"><button class='ant-btn ant-btn-default'>${e}</button></a>`
              );
            },
          },
          { data: "frequency", title: "Frequency" ,orderable:false,render: function(e){
            return (
              e && `<span>${e} Conversations</span>`
            );
          }},
        ],
      });
    } catch (error) {
      setIsError(error);
    } finally {
      setLoading(false);
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy(true);
      }
    };
  }, []);
 
    return (
      <>
        {!isLoading ? (
          <div className="container mt-0 w-100">
            <Card
              bordered={false}
              className="header-solid mb-24 tableBorder"
            >
              <div className="inputCustomerContainer">
                <div className="px-25"></div>
              </div>
              {isError ? (
                <Alert
                  className="m-4 text-center"
                  message={`Error: ${isError}`}
                  type="error"
                />
              ) : (
                <div className="table customTable w-100 m-0 align-item-center justify-content-center">
                  <table
                    ref={tableRef}
                    className="ant-table"
                    style={{ width: "100%" }}
                  >
                    <thead className="ant-table-thead">
                      <tr className="ant-table-row">
                        <th className="ant-table-cell">Prompts</th>
                        <th className="ant-table-cell">Category</th>
                        <th className="ant-table-cell">Frequency</th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody"></tbody>
                  </table>
                </div>
              )}
            </Card>
          </div>
        ) : (
          <Loader />
        )}
      </>
    );
  }

  export default Queries;