const satisfaction = [
  <svg
    width="38"
    height="38"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.15 15.3709C12.93 15.0509 12.54 14.8709 12.09 14.8709H9.76C9.61 14.8709 9.46 14.8109 9.37 14.6909C9.27 14.5709 9.23 14.4209 9.25 14.2509L9.54 12.3909C9.66 11.8409 9.29 11.2109 8.74 11.0209C8.22 10.8309 7.62 11.0909 7.37 11.4609L5.06 14.9009V14.4709C5.06 13.6309 4.7 13.2909 3.82 13.2909H3.24C2.36 13.3009 2 13.6409 2 14.4809V20.1909C2 21.0309 2.36 21.3709 3.24 21.3709H3.82C4.66 21.3709 5.01 21.0409 5.04 20.2809L6.79 21.6309C7.04 21.8709 7.57 22.0009 7.95 22.0009H10.16C10.92 22.0009 11.68 21.4309 11.86 20.7309L13.26 16.4809C13.41 16.0709 13.37 15.6809 13.15 15.3709Z"
      fill="#fff"
    />
    <path
      d="M21.1108 2.61047H20.5308C19.6908 2.61047 19.3408 2.94047 19.3008 3.70047L17.5508 2.35047C17.3108 2.11047 16.7708 1.98047 16.3908 1.98047H14.1808C13.4208 1.98047 12.6608 2.55047 12.4808 3.25047L11.0808 7.50047C10.9308 7.91047 10.9808 8.30047 11.1908 8.61047C11.4108 8.93047 11.8008 9.11047 12.2508 9.11047H14.5808C14.7308 9.11047 14.8808 9.17047 14.9708 9.29047C15.0708 9.41047 15.1108 9.56047 15.0908 9.73047L14.8008 11.5905C14.6808 12.1405 15.0508 12.7705 15.6008 12.9605C16.1208 13.1505 16.7208 12.8905 16.9708 12.5205L19.2808 9.08047V9.51047C19.2808 10.3505 19.6408 10.6905 20.5208 10.6905H21.1008C21.9808 10.6905 22.3408 10.3505 22.3408 9.51047V3.78047C22.3508 2.95047 21.9908 2.61047 21.1108 2.61047Z"
      fill="#fff"
    />
  </svg>,
];

const current = [
  <svg
    width="49"
    height="49"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
      fill="#5889E0"
    />
    <path
      d="M14.0809 14.1489C11.2909 12.2889 6.74094 12.2889 3.93094 14.1489C2.66094 14.9989 1.96094 16.1489 1.96094 17.3789C1.96094 18.6089 2.66094 19.7489 3.92094 20.5889C5.32094 21.5289 7.16094 21.9989 9.00094 21.9989C10.8409 21.9989 12.6809 21.5289 14.0809 20.5889C15.3409 19.7389 16.0409 18.5989 16.0409 17.3589C16.0309 16.1289 15.3409 14.9889 14.0809 14.1489Z"
      fill="#5889E0"
    />
    <path
      d="M19.9894 7.33815C20.1494 9.27815 18.7694 10.9781 16.8594 11.2081C16.8494 11.2081 16.8494 11.2081 16.8394 11.2081H16.8094C16.7494 11.2081 16.6894 11.2081 16.6394 11.2281C15.6694 11.2781 14.7794 10.9681 14.1094 10.3981C15.1394 9.47815 15.7294 8.09815 15.6094 6.59815C15.5394 5.78815 15.2594 5.04815 14.8394 4.41815C15.2194 4.22815 15.6594 4.10815 16.1094 4.06815C18.0694 3.89815 19.8194 5.35815 19.9894 7.33815Z"
      fill="#5889E0"
    />
    <path
      d="M21.9883 16.5904C21.9083 17.5604 21.2883 18.4004 20.2483 18.9704C19.2483 19.5204 17.9883 19.7804 16.7383 19.7504C17.4583 19.1004 17.8783 18.2904 17.9583 17.4304C18.0583 16.1904 17.4683 15.0004 16.2883 14.0504C15.6183 13.5204 14.8383 13.1004 13.9883 12.7904C16.1983 12.1504 18.9783 12.5804 20.6883 13.9604C21.6083 14.7004 22.0783 15.6304 21.9883 16.5904Z"
      fill="#5889E0"
    />
  </svg>,
];

const dashboardIcon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="fff"
    ></path>
    <path
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="fff"
    ></path>
    <path
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="fff"
    ></path>
  </svg>,
];

const filterIcon = [
  <svg
    width="17"
    height="17"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 22V11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 7V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22V17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 22V11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11H7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 11H21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13H14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
];

const searchIcon = [
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeminecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9299 20.6898C19.4599 22.2898 20.6699 22.4498 21.5999 21.0498C22.4499 19.7698 21.8899 18.7198 20.3499 18.7198C19.2099 18.7098 18.5699 19.5998 18.9299 20.6898Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeminecap="round"
      strokeLinejoin="round"
    />
  </svg>,
];

export {
  satisfaction,
  current,
  dashboardIcon,
  filterIcon,
  searchIcon,
};
