const modulesData = [
    {
      "Module": "Upselling Module",
      "Description": "Boost sales effortlessly with WOZ's smart upselling module.",
      "Status": "Active",
      "Info": ""
    },
    {
      "Module": "Membership Sign Up Module",
      "Description": "Seamlessly enroll users with WOZ's efficient membership sign-up module.",
      "Status": "Inactive",
      "Info": "Show"
    },
    {
      "Module": "Loyalty Program Module",
      "Description": "Enhance customer retention with WOZ's rewarding loyalty program module.",
      "Status": "Error",
      "Info": "Show"
    },
    {
      "Module": "Module 4",
      "Description": "Shoret description for module",
      "Status": "Active",
      "Info": ""
    }
  ];

  export default modulesData;

