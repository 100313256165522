import React, { Component, useEffect, useState, useRef, useCallback } from "react";
import { Card, Col, Row, Button, Select, DatePicker } from "antd";
import { } from "@ant-design/icons";
import { Radio } from "antd";
import Title from "antd/lib/typography/Title";
import LineChart from "../../chart/LineChart";
import $ from "jquery";
import "datatables.net";
import Engagement from "../../../data/EngagementOverview";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CampaignMetrics = () => {
  const [value, setValue] = useState(0);
  const tableRef = useRef(null);
  const table = $(tableRef.current).DataTable();
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);
  const productId = getProductIdFromLocalStorage();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("userToken");

  const tabItem = [
    {
      id: 0,
      title: "Upsells",
    },
    {
      id: 1,
      title: "Membership Sign-ups",
    },
    {
      id: 2,
      title: "Loyalty Program",
    },
  ];

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!tableRef.current) return;
    setLoading(true);
    // const url = `${process.env.REACT_APP_BASE_URL}/${productId}/customer-data`;
    try {
      $(tableRef.current).DataTable({
        // serverSide: true,
        // processing: true,
        dom: "<'col-auto overflow-auto't><'footerContainer overflow-auto'<'dt-layout-cell dt-start'i><'dt-layout-cell dt-end'p>>",
        "language": {
          "search": '<div class="customSearchSvg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.25 15C11.9779 15 15 11.9779 15 8.25C15 4.52208 11.9779 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.9779 4.52208 15 8.25 15Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.1973 15.5174C14.5948 16.7174 15.5023 16.8374 16.1998 15.7874C16.8373 14.8274 16.4173 14.0399 15.2623 14.0399C14.4073 14.0324 13.9273 14.6999 14.1973 15.5174Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          "searchPlaceholder": "Search",
        },
        // ajax: {
        //   url: url,
        //   type: "POST",
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // },

        data: Engagement,
        order: [0, "desc"],
        columns: [
          { data: "Date", title: "Date" },
          { data: "Impressions", title: "Impressions" },
          { data: "Clicks", title: "Clicks" },
          {
            data: "CTR",
            title: "CTR (%)",
            render: function(e){
              return `<span>${e} %</span>`
            }
          },
          { data: "Conversions", title: "Conversions" },
        ],
      });
    } catch (error) {
      setIsError(error.message);
    } finally {
      setLoading(false);
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy(true);
      }
    };
  }, [productId]);

  const count = [
    [
      {
        today: "Impressions",
        title: "12342",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
              fill="white"
            />
            <path
              d="M20.7896 14.6999C19.6696 15.4499 18.0996 15.7299 16.6496 15.5399C17.0296 14.7199 17.2296 13.8099 17.2396 12.8499C17.2396 11.8499 17.0196 10.8999 16.5996 10.0699C18.0796 9.86991 19.6496 10.1499 20.7796 10.8999C22.3596 11.9399 22.3596 13.6499 20.7896 14.6999Z"
              fill="white"
            />
            <path
              d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
              fill="white"
            />
            <path
              d="M6.54914 12.8501C6.54914 13.8201 6.75914 14.7401 7.13914 15.5701C5.72914 15.7201 4.25914 15.4201 3.17914 14.7101C1.59914 13.6601 1.59914 11.9501 3.17914 10.9001C4.24914 10.1801 5.75914 9.8901 7.17914 10.0501C6.76914 10.8901 6.54914 11.8401 6.54914 12.8501Z"
              fill="white"
            />
            <path
              d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
              fill="white"
            />
            <path
              d="M8.87078 17.9401C7.36078 18.9501 7.36078 20.6101 8.87078 21.6101C10.5908 22.7601 13.4108 22.7601 15.1308 21.6101C16.6408 20.6001 16.6408 18.9401 15.1308 17.9401C13.4208 16.7901 10.6008 16.7901 8.87078 17.9401Z"
              fill="white"
            />
          </svg>
        ),
      },
      {
        today: "Click Through Rate (CTR)",
        title: "28%",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.1391 2.96004L7.10914 5.96004C1.03914 7.99004 1.03914 11.3 7.10914 13.32L9.78914 14.21L10.6791 16.89C12.6991 22.96 16.0191 22.96 18.0391 16.89L21.0491 7.87004C22.3891 3.82004 20.1891 1.61004 16.1391 2.96004ZM16.4591 8.34004L12.6591 12.16C12.5091 12.31 12.3191 12.38 12.1291 12.38C11.9391 12.38 11.7491 12.31 11.5991 12.16C11.3091 11.87 11.3091 11.39 11.5991 11.1L15.3991 7.28004C15.6891 6.99004 16.1691 6.99004 16.4591 7.28004C16.7491 7.57004 16.7491 8.05004 16.4591 8.34004Z"
              fill="white"
            />
          </svg>
        ),
      },
      {
        today: "Total Clicks",
        title: "2341",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 7.52V13.4C21 13.74 20.67 13.98 20.35 13.88L16.42 12.66C15.34 12.33 14.18 12.61 13.39 13.4C12.59 14.2 12.3 15.37 12.64 16.45L13.85 20.35C13.95 20.67 13.71 21 13.37 21H7.52C4.07 21 2 18.94 2 15.48V7.52C2 4.06 4.07 2 7.52 2H15.48C18.93 2 21 4.06 21 7.52Z"
              fill="white"
            />
            <path
              d="M21.9597 18.84L20.3297 19.39C19.8797 19.54 19.5197 19.89 19.3697 20.35L18.8197 21.98C18.3497 23.39 16.3697 23.36 15.9297 21.95L14.0797 16C13.7197 14.78 14.8197 13.68 16.0397 14.04L22.0097 15.9C23.4097 16.34 23.4297 18.32 21.9597 18.84Z"
              fill="white"
            />
          </svg>
        ),
      },
    ]
  ];

  return (
    <>
      <div className="layout-content">
        <div className="tabs-container">
          <Row className="justify-content-between align-items-center">
            <Radio.Group
              onChange={onChange}
              value={value}
              className="custom-tabs pt-2"
            >
              {tabItem &&
                tabItem.map((item, index) => {
                  return (
                    <Radio
                      value={item.id}
                      className={
                        value === item.id ? "tab selected" : "tab"
                      }
                      key={index}
                    >
                      {item.title}
                    </Radio>
                  );
                })}
            </Radio.Group>
            <RangePicker className="mb-3"/>
          </Row>
          <div className="tab-content">
            <div>
              <Row gutter={[24, 0]}>
                <Col  xs={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      span={24}
                      className="zindex m-0 p-0 pt-2">
                  <Row gutter={[24, 0]}>
                    {count &&
                      count[0].map((item, index) => {
                        return (
                          <Col
                            xs={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={8}
                            span={24}
                            className="mb-24"
                            key={index}
                          >
                            <Card
                              bordered={false}
                              className={`crm-bar-line header-solid ${item.icon && "dashIconStyles"
                                }`}
                            >
                              <div
                                className={`dashTileFlex satisfaction`}
                              >
                                <div>
                                  <h6 className="text-md font-semibold text-muted">
                                    {item.today}
                                  </h6>
                                  {item.subTitle ? (
                                    <h1>{item.subTitle}</h1>
                                  ) : (
                                    <h5 className="mb-0">{item.title} </h5>
                                  )}
                                </div>
                                {item.icon && (
                                  <div className="icon-box icon-box-custom">
                                    {item.icon}
                                  </div>
                                )}
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
              <Card className="mb-4">
                <LineChart />
              </Card>
              <Card
                bordered={false}
                className="header-solid mb-24"
                title={[<Title level={4}>Engagement Overview</Title>]}
              >
                <div className="customTable customerTable text-center">
                  <table
                    ref={tableRef}
                    className="table table-responsive"
                    style={{ width: "100%" }}
                  >
                    <thead className="ant-table-thead">
                      <tr className="ant-table-row">
                        <th className="ant-table-cell text-center">Date</th>
                        <th className="ant-table-cell text-center">Impressions</th>
                        <th className="ant-table-cell text-center">Clicks</th>
                        <th className="ant-table-cell text-center">CTR (%)</th>
                        <th className="ant-table-cell text-center">Conversions</th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody"></tbody>
                  </table>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignMetrics;
