const Engagement = [
    {
      "Date": "28 June 2024",
      "Impressions": 1763,
      "Clicks": 214,
      "CTR": 1.67,
      "Conversions": 12
    },
    {
      "Date": "27 June 2024",
      "Impressions": 3136,
      "Clicks": 492,
      "CTR": 1.8,
      "Conversions": 27
    },
    {
      "Date": "26 June 2024",
      "Impressions": 6607,
      "Clicks": 181,
      "CTR": 1.41,
      "Conversions": 43
    },
    {
      "Date": "25 June 2024",
      "Impressions": 7091,
      "Clicks": 441,
      "CTR": 3.9,
      "Conversions": 11
    },
    {
      "Date": "24 June 2024",
      "Impressions": 2959,
      "Clicks": 97,
      "CTR": 0.95,
      "Conversions": 13
    },
    {
      "Date": "23 June 2024",
      "Impressions": 4907,
      "Clicks": 261,
      "CTR": 4.01,
      "Conversions": 26
    },
    {
      "Date": "22 June 2024",
      "Impressions": 9065,
      "Clicks": 83,
      "CTR": 4.4,
      "Conversions": 26
    },
    {
      "Date": "21 June 2024",
      "Impressions": 3924,
      "Clicks": 413,
      "CTR": 3.15,
      "Conversions": 40
    },
    {
      "Date": "20 June 2024",
      "Impressions": 4516,
      "Clicks": 248,
      "CTR": 4.7,
      "Conversions": 14
    },
    {
      "Date": "19 June 2024",
      "Impressions": 4736,
      "Clicks": 323,
      "CTR": 3.61,
      "Conversions": 21
    }
  ];

  export default Engagement;
  