import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { withRouter } from "react-router-dom";

class Echart extends Component {
  constructor(props) {
    super(props);
    const labels = props.title === "topic" ? props?.labels : props?.xKeys || [];

    const Event =
      props?.title === "topic"
        ? {
            events: {
              dataPointSelection: (event, chartContext, config) => {
                this.handleBarClick(event, chartContext, config);
              },
            },
          }
        : { events: {} };
    this.state = {
      series: [
        {
          name: "New Users",
          data: props?.new || [],
          color: "#36a2eb",
        },
        {
          name: "Returning Users",
          data: props?.returning || [],
          color: "#ff6384",
        },
      ],
      topicSeries: props?.series,
      options: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          events: Event?.events,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            columnWidth: "60%",
            endingShape: "flat",
            dataLabels: {
              enabled: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#f1f1f1",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
            
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Math.round(val);
            }
          },
        },
        legend: {
          show: true,
          position: "top",
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          markers: {
            radius: 10,
            width: 25,
          },
          itemStyle: {
            fontSize: "14px",
          },
        },
        xaxis: {
          categories: labels,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return Math.round(val);
            },
          },
        },
      },
      productID: JSON.parse(localStorage.getItem('selectedProduct')) || 1
    };
  }

  handleBarClick(event, chartContext, config) {
    const { dataPointIndex, seriesIndex } = config;
    const topic = this.props?.labels[dataPointIndex];
    const sentiment = this.props?.series[seriesIndex].name;
    const url = `/${this.state.productID.id}/analysis/chat-history?topic=${topic}&sentiment=${sentiment}`;
    this.props.history.push(url);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.new !== this.props.new ||
      prevProps.returning !== this.props.returning ||
      prevProps.series !== this.props.series ||
      prevProps.labels !== this.props.labels ||
      prevProps.xKeys !== this.props.xKeys
    ) {
      const labels =
        this.props.title === "topic"
          ? this.props?.labels
          : this.props?.xKeys || [];

      this.setState({
        series: [
          {
            name: "New Users",
            data: this.props?.new || [],
            color: "#36a2eb",
          },
          {
            name: "Returning Users",
            data: this.props?.returning || [],
            color: "#ff6384",
          },
        ],
        topicSeries: this.props?.series,
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: labels,
          },
        },
      });
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={
            this.props.title === "topic"
              ? this.state.topicSeries
              : this.state.series
          }
          type="bar"
          height={330}
        />
      </div>
    );
  }
}

export default withRouter(Echart);
