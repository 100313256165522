  import React, { useState, useEffect } from "react";
  import { Input, Layout, Row, Col, Card, Button, Pagination } from "antd";
  import { useHistory } from "react-router-dom";
  import { searchIcon } from "../../data/homeData";
  import wozLogo from "../images/WOZLogoDark.svg";
  import Loader from "../loader";
  import productService from "../../services/product-service";

  const ChooseProduct = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [searchText, setSearchText] = useState("");
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(() => {
      return JSON.parse(localStorage.getItem('selectedProduct')) || null;
    });
    const history = useHistory();


    useEffect(() => {
      const fetchProducts = async () => {
        // Conditionally set loading state based on the initial load or search/page change
        if (!searchText && currentPage === 1) {
          setIsLoading(true);
        }
    
        try {
          const response = await productService.getProducts({
            params: { search_term: searchText, page: currentPage },
          });
    
          setProducts(response?.data);
          setError(null);
        } catch (err) {
          if (err.name === 'AbortError') {
            // Handle request cancellation
            console.log('Request was aborted');
          } else {
            // Handle other errors
            setError(err.response?.data || err.message);
          }
        } finally {
          setIsLoading(false);
        }
      };
    
      console.log("Fetching products");
    
      fetchProducts();
    
      // Clean up function to handle component unmount
      return () => {
        productService.abortRequest(); // Abort any ongoing request when the component unmounts
      };
    }, [searchText, currentPage]);
    

    const handlePageChange = (page) => setCurrentPage(page);
    const handleInputChange = (e) => {
      setSearchText(e.target.value);
      setCurrentPage(1);
    };

    const handleCardClick = (product) => {
      setSelectedProduct(product);
      localStorage.setItem('selectedProduct', JSON.stringify(product));
    };

    const handleContinue = () => {
      if (selectedProduct) {
        history.push(`/${selectedProduct.id}/dashboard`);
      }
    };

    if (error) return <div>Error: {error}</div>;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentProducts = products?.slice(startIndex, startIndex + itemsPerPage);

    const totalItems = products?.length;

    return (
      <Layout className="productContainer">
        {isLoading ? (
          <Loader loader="loader" />
        ) : (
          <Row gutter={[24, 24]} className="productRowContainer">
            <div className="productCompanyHeader">
              <img
                alt="Product Icon"
                src={wozLogo}
                width={"214px"}
                height={"88px"}
                className="mb-2"
              />
              <p className="descriptionProduct">
                Select the product dashboard you want to view
              </p>
            </div>
            <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
              <Input
                className="productSearch"
                placeholder="Search For Products"
                size="small"
                allowClear
                suffix={searchIcon}
                onChange={handleInputChange}
              />
            </Col>
            <Row
              gutter={[12, 12]}
              justify={"center"}
              align={"center"}
              style={{ width: "100%" }}
            >
              {currentProducts?.map((item, index) => (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={index}>
                  <Card
                    bordered={false}
                    cover={
                      <img
                        alt={item.name}
                        src={item.image}
                        className="productImage"
                      />
                    }
                    onClick={() => handleCardClick(item)}
                    className="productCard"
                    style={{
                      border:
                        selectedProduct === item
                          ? "2px solid #1890FF"
                          : "2px solid transparent",
                      cursor: "pointer",
                      boxShadow:
                        selectedProduct === item
                          ? "0 4px 8px rgba(130 20 217 / 16%), 0 6px 20px rgb(255 25 25 / 0%)"
                          : "none",
                    }}
                  >
                    <Card.Meta
                      title={item.name}
                      description={
                        <>
                          <p className="product-info">{item.user}</p>
                          <p className="product-info">{item.conversation}</p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="chooseProductPagination">
              {totalItems > 6 && (
                <Pagination
                  current={currentPage}
                  total={totalItems}
                  pageSize={itemsPerPage}
                  onChange={handlePageChange}
                />
              )}
            </div>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Button
                type="primary"
                onClick={handleContinue}
                className="ContinueButton mb-4"
                disabled={!selectedProduct}
              >
                Continue
              </Button>
            </Col>
          </Row>
        )}
      </Layout>
    );
  };

  export default ChooseProduct;
