import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Layout,
  Row,
  Col,
  Card,
  Alert,
} from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import wozMobile from "../images/WOZ.svg";
import wozImage from "../images/WOZLogo.svg";
import { AuthContext } from "../../context";

const { Content } = Layout;

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { login, isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

useEffect(()=>{
  if(isAuthenticated){
    history.push('/choose-product')
  }
}, [isAuthenticated, history])  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  const onFinish = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      await login({ email, password });
      history.push("/choose-product");
    } catch (error) {
      setErrorMessage("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    setErrorMessage("Please check your inputs and try again.");
    setLoading(false);
  };

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin signinHeight">
        <Row
          gutter={[24, 24]}
          justify="center"
          align="center"
          className="signinRow"
        >
          <Col className="loginSideContainer1">
            <div className="sideContainer">
              <img
                src={wozMobile}
                alt="login woz"
                style={{ width: "65px", height: "37px" }}
                className="loginWozImagemb"
              />
              <h1 className="h1">Log in to Your Account</h1>
              <p>
                Explore how WOZ delivers personalized, interactive experiences
                that captivate, engage and retain your customers.
              </p>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={12}
            xl={12}
            xxl={12}
            className="signinPadding"
          >
            <Card bordered={false} className="signinCard">
              <div className="sininDetailsContainer">
                <h3 className="h3">Sign in</h3>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Email Address"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email!" },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  <div className="forgotPass">
                    <Form.Item>
                      <Checkbox name="keepLoggedIn" className="keepLoggedIn">
                        Keep me logged in
                      </Checkbox>
                      <Link to="/forgot-password" className="forgot-password">
                        Forgot password?
                      </Link>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "100%",
                        backgroundColor: "#190041",
                        border: "none",
                      }}
                      block={true}
                      disabled={loading}
                      icon={loading ? <LoadingOutlined /> : null}
                    >
                      {loading ? "Signing In..." : "SIGN IN"}
                    </Button>
                  </Form.Item>
                  {errorMessage && (
                    <Alert
                      message={errorMessage}
                      type="error"
                      showIcon
                      className="signin-alerts mb-4"
                    />
                  )}
                </Form>
              </div>
              <footer className="footer-copyright">
                <p className="text-mute">
                  Copyright 2024 WOZ. All Rights Reserved.
                </p>
              </footer>
            </Card>
          </Col>
          <Col lg={8} xl={8} xxl={8} className="loginSideContainer">
            <div className="sideContainer">
              <img
                src={wozImage}
                alt="login woz"
                style={{ width: "125px", height: "29px" }}
                className="loginWozImage"
              />
              <h1 className="h1">Log in to Your Account</h1>
              <p>
                Explore how WOZ delivers personalized, interactive experiences
                that captivate, engage and retain your customers.
              </p>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default SignIn;
