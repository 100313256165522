import React, { createContext, useState } from 'react';
import moment from 'moment';

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
    const [date, setDate] = useState([moment().subtract(1,'month'), moment()])

  return (
    <DateContext.Provider
      value={{
        setDate,
        date
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
