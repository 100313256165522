import Axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
Axios.defaults.baseURL = baseURL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url, params = {}, options = {}) => 
    await this.request(this.getOptionsConfig("get", url, null, params, options));

  post = async (url, data = {}, params = {}, options = {}) => 
    await this.request(this.getOptionsConfig("post", url, data, params, options));

  getOptionsConfig = (method, url, data = null, params = {}, options = {}) => {
    const userToken = localStorage.getItem('userToken');
    
    // Include any options like signal or additional headers
    return {
      method,
      url,
      data,
      params,
      headers: { 
        "Content-Type": "application/json", 
        "Authorization": `Bearer ${userToken}` 
      },
      ...options, // Spread any additional options, e.g., { signal: abortSignal }
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => {
          if (Axios.isCancel(ex)) {
            reject({ name: 'AbortError', message: 'Request was aborted' });
          } else {
            reject(ex.response ? ex.response.data : ex);
          }
        });
    });
  }
}

const httpService = new HttpService();
export default httpService;
