import Home from "../component/pages/Home";
// import Stage from "../component/pages/conversationStage/Stage";
import Analysis from "../component/pages/conversation-analysis";
import Setting from "../component/pages/Setting";
import ChatHistory from "../component/pages/chatHistory/Chathistory";
import CustomerData from "../component/pages/customerData/customer";
import SignIn from "../component/pages/SignIn";
import ForgotPassword from "../component/pages/ForgotPassword"
import Recovery from "../component/pages/Recovery"
import ChooseProduct from "../component/pages/ChooseProduct"
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ResetPassword from "../component/pages/ResetPassword";
import Queries from "../component/pages/queries/commonQueries";
import CampaignMetrics from "../component/pages/campaignMetrics/Campaign-Metrics";
import ChatDetails from "../component/pages/chatDetails/chatDetails";


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('userToken') ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};
const routes = [
  {
    path: "/:id/dashboard",
    exact: true,
    name: "Default",
    component: Home,
  },
  {
    path: "/:id/analysis/conversation-analysis",
    exact: true,
    name: "Analysis",
    component: Analysis,
  },
  {
    path: "/:id/settings",
    exact: true,
    name: "Settings",
    component: Setting,
  },
  {
    path: "/:id/analysis/chat-history",
    exact: true,
    name: "Chat History",
    component: ChatHistory,
  },
  {
    path: "/:id/analysis/common-queries",
    exact: true,
    name: "Common Queries",
    component: Queries,
  },
  {
    path: "/:id/customer-data",
    exact: true,
    name: "Customer Data",
    component: CustomerData,
  },
  {
    path: "/:id/campaign-metrics",
    exact: true,  
    name: "Campaign Metrics",
    component: CampaignMetrics,
  },
];

const extraRoutes = [
  {
    path: "/signin",
    exact: true,
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/recovery",
    exact: true,
    name: "Recovery",
    component: Recovery,
  },
  {
    path: "/choose-product",
    exact: true,
    name: "Choose Product",
    component: ChooseProduct,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/chat-details",
    exact: true,
    name: "Chat Details",
    component: ChatDetails,
  },
];

function Routing() {
  return (
    <Router>
      <Switch>
        {routes.map((route, idx) => (
          <PrivateRoute
            key={idx}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        {extraRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}

export {extraRoutes, routes} ;
