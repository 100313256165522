import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Alert, Button, Modal, Tabs, Space, Select, DatePicker, Tag } from "antd";
import Loader from "../../loader";
import $ from "jquery";
import "datatables.net";
import { filterIcon } from "../../../data/homeData";
import ReactDOM from 'react-dom';
import DebounceSelect from "../../Debounce";

const { RangePicker } = DatePicker;
const CustomerData = () => {
  const selectedFilter = "Filters";
  const tableRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [OSselected, setSelectedOS] = useState([]);
  const [Locationselected, setSelectedLocation] = useState([]);
  const [selectedDates, setSelectedDates] = useState(null);
  const wrapper = document.getElementById('filter');
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const FilterMenu = ["By Location", "By OS", "By Date"];
  const token = localStorage.getItem("userToken");
  const table = $(tableRef.current).DataTable();
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);
  const productId = getProductIdFromLocalStorage();

  async function fetchOptions() {
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/filter-data/device`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      return Object.keys(data.data).map(key => ({
        label: (String(data.data[key]).toLowerCase()=== "ios")?"iOS":String(data.data[key]),
        value: (key.toLowerCase() === "ios")? "iOS":key,
      }));
    
    } catch (error) {
      console.error('Error fetching OS options:', error);
      return [];
    }
  }

  async function fetchLocationsOptions(value) {
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/filter-data/country?search=${value}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      return Object.keys(data.data).map(key => ({
        label: String(data.data[key]),
        value: key,
      }));
    } catch (error) {
      console.error('Error fetching location options:', error);
      return [];
    }
  }

  const handleOSChange = (values) => {
    const selectedValues = values.map(item => item.value);
    setSelectedOS(selectedValues);
  };
  const handleLocationChange = (values) => {
    const selectedValues = values.map(item => item.value);
    setSelectedLocation(selectedValues);
  };

  const handleDateChange = (dates) => {
    setSelectedDates([dates[0], dates[1]]);
  };

  const handleClearFilters = ()=>{
    setSelectedOS([]);
    setSelectedDates(null);
    setSelectedLocation([]);
    setOpen(false);
    table.column(4).search("", true, false).draw();
    table.column(2).search("", true, false).draw();
    table.column(5).search("", true, false).draw();
  }

  const ScreenOne = () => {
    const options = fetchLocationsOptions();
    return (
      <div>
        <Space
          style={{
            width: '100%',
          }}
          direction="vertical"
        >
          <DebounceSelect
            mode="multiple"
            value={Locationselected}
            placeholder="Please select Location"
            fetchOptions={fetchLocationsOptions}
            onChange={handleLocationChange}
            style={{
              width: '100%',
            }}
          />
        </Space>
      </div>);
  }

  const ScreenTwo = () => (
    <div>
      <Space
        style={{
          width: '100%',
        }}
        direction="vertical"
      >
        <DebounceSelect
          mode="multiple"
          value={OSselected}
          placeholder="Please select OS"
          fetchOptions={fetchOptions}
          onChange={handleOSChange}
          style={{
            width: '100%',
          }}
        />
      </Space>
    </div>
  );

  const ScreenThree = () => (
    <div>
      <RangePicker
        value={selectedDates}
        onChange={handleDateChange}
        format="YYYY-MM-DD"
        allowClear
      />
    </div>
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    table.column(4).search(OSselected.join("|"), true, false).draw();
    table.column(2).search(Locationselected.join("|"), true, false).draw();
    if (selectedDates) {
      const packet = {
        "start": selectedDates[0].format("YYYY-MM-DD"),
        "end": selectedDates[1].format("YYYY-MM-DD")
      }
      table.column(5).search(JSON.stringify(packet), true, false).draw();
    }


  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (wrapper) {
      const customFilterDiv = document.getElementById('custom-filter-wrapper') || document.createElement('div');
      customFilterDiv.id = 'custom-filter-wrapper';

      ReactDOM.render(
        <>
          <Button className="filterButton outline" onClick={showModal}>
            <div className="icon">
              {filterIcon}
            </div>
            {selectedFilter}
            {(OSselected.length > 0 ||
              Locationselected.length > 0 || selectedDates) && (
                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-primary border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
          </Button>
        </>,
        customFilterDiv
      );

      if (!document.getElementById('custom-filter-wrapper')) {
        wrapper.appendChild(customFilterDiv);
      }
    }
  }, [wrapper, OSselected, Locationselected, selectedDates]);

  useEffect(() => {
    if (!tableRef.current) return;
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/${productId}/customer-data`;

    try {
      const table = $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        dom: "<'row justify-content-between align-item-center'<'col-auto col-md-3 col-lg-3 mb-2 'l><'col-md-9 col-sm-12 col-12'<'row justify-content-end align-item-center'<'col-xxl-4 col-xl-4 col-lg-4 col-sm-12 col-md-12 mb-2'bf><'#filter.col-md-3'>>>><'#customBadges.mb-2'><'col-auto overflow-auto't><'footerContainer overflow-auto'<'dt-layout-cell dt-start'i><'dt-layout-cell dt-end'p>>",
        "language": {
          "search": '<div class="customSearchSvg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.25 15C11.9779 15 15 11.9779 15 8.25C15 4.52208 11.9779 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.9779 4.52208 15 8.25 15Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.1973 15.5174C14.5948 16.7174 15.5023 16.8374 16.1998 15.7874C16.8373 14.8274 16.4173 14.0399 15.2623 14.0399C14.4073 14.0324 13.9273 14.6999 14.1973 15.5174Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          "searchPlaceholder": "Search",
        },
        ajax: {
          url: url,
          type: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        order: [0, "desc"],
        columns: [
          { data: "name", title: "Name" },
          { data: "email", title: "Email" },
          { data: "country", title: "Location" },
          {
            data: "rating",
            title: "Product Rating",
            orderable: false,
            searchable: false,
            render: function (e) {
              return e && `<button class="ant-btn ant-btn-default">${e}</button>`;
            },
          },
          { data: "device", title: "OS", render: function(e){
            return (e.toLowerCase() === "ios")?`<span style="text-transform: none !important">iOS</span>`:e ;
          } },
          { data: "last_session", title: "Last Session" },
          {
            data: "id",
            title: "Action",
            render: function (e) {
              return `<a href="/${productId}/analysis/chat-history?customer_id=${e.toString()}">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5799 12C15.5799 13.98 13.9799 15.58 11.9999 15.58C10.0199 15.58 8.41992 13.98 8.41992 12C8.41992 10.02 10.0199 8.42004 11.9999 8.42004C13.9799 8.42004 15.5799 10.02 15.5799 12Z" stroke="#5889E0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" stroke="#5889E0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </a>`;
            },
          },
        ],
      });
      $('#filter').addClass('customfilter col-xl-2 col-xxl-2');
    } catch (error) {
      setIsError(error.message);
    } finally {
      setLoading(false);
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy(true);
      }
    };
  }, [productId]);

  return (
    <>
      {!isLoading ? (
        <div className="container mt-0 w-100">
          <Modal
            title="Filters"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
            footer={[
              <Button key="clear" onClick={handleClearFilters}>
                Clear Filters
              </Button>,
              <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                Apply Filters
              </Button>,
            ]}
          >
            <Tabs
              defaultActiveKey="1"
              tabPosition="left"
              items={FilterMenu.map((value, i) => {
                const id = String(i + 1);
                return {
                  key: id,
                  label: `${value}`,
                  children: id === "1" ? <ScreenOne /> : id === "2" ? <ScreenTwo /> : <ScreenThree />,
                };
              })}
            />
          </Modal>
          <Card
            bordered={false}
            className="header-solid mb-24 tableBorder"
          >
            {isError ? (
              <Alert
                className="m-4 text-center"
                message={`Error: ${isError}`}
                type="error"
              />
            ) : (
              <div className="customTable customerTable">
                <table
                  ref={tableRef}
                  className="table table-responsive"
                  style={{ width: "100%" }}
                >
                  <thead className="ant-table-thead">
                    <tr className="ant-table-row">
                      <th className="ant-table-cell">Name</th>
                      <th className="ant-table-cell">Email</th>
                      <th className="ant-table-cell">Location</th>
                      <th className="ant-table-cell">Product Rating</th>
                      <th className="ant-table-cell">OS</th>
                      <th className="ant-table-cell">Last Session</th>
                      <th className="ant-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody"></tbody>
                </table>
              </div>
            )}
          </Card>

        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CustomerData;
