import React from "react";
import PropTypes from "prop-types";

const getSvgIcon = (iconName) => {
  switch (iconName) {
    case "box":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.2083 7.82141L12.5083 12.2814C12.1983 12.4614 11.8083 12.4614 11.4883 12.2814L3.78826 7.82141C3.23826 7.50141 3.09826 6.75141 3.51826 6.28141C3.80826 5.95141 4.13826 5.68141 4.48826 5.49141L9.90826 2.49141C11.0683 1.84141 12.9483 1.84141 14.1083 2.49141L19.5283 5.49141C19.8783 5.68141 20.2083 5.96141 20.4983 6.28141C20.8983 6.75141 20.7583 7.50141 20.2083 7.82141Z"
            fill="currentColor"
          />
          <path
            d="M11.4305 14.1389V20.9589C11.4305 21.7189 10.6605 22.2189 9.98047 21.8889C7.92047 20.8789 4.45047 18.9889 4.45047 18.9889C3.23047 18.2989 2.23047 16.5589 2.23047 15.1289V9.9689C2.23047 9.1789 3.06047 8.6789 3.74047 9.0689L10.9305 13.2389C11.2305 13.4289 11.4305 13.7689 11.4305 14.1389Z"
            fill="currentColor"
          />
          <path
            d="M12.5703 14.1389V20.9589C12.5703 21.7189 13.3403 22.2189 14.0203 21.8889C16.0803 20.8789 19.5503 18.9889 19.5503 18.9889C20.7703 18.2989 21.7703 16.5589 21.7703 15.1289V9.9689C21.7703 9.1789 20.9403 8.6789 20.2603 9.0689L13.0703 13.2389C12.7703 13.4289 12.5703 13.7689 12.5703 14.1389Z"
            fill="currentColor"
          />
        </svg>
      );
    case "home":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0383 6.82165L14.2783 2.79165C12.7083 1.69165 10.2983 1.75165 8.78828 2.92165L3.77828 6.83165C2.77828 7.61165 1.98828 9.21165 1.98828 10.4716V17.3716C1.98828 19.9216 4.05828 22.0016 6.60828 22.0016H17.3883C19.9383 22.0016 22.0083 19.9316 22.0083 17.3816V10.6016C22.0083 9.25165 21.1383 7.59165 20.0383 6.82165ZM12.7483 18.0016C12.7483 18.4116 12.4083 18.7516 11.9983 18.7516C11.5883 18.7516 11.2483 18.4116 11.2483 18.0016V15.0016C11.2483 14.5916 11.5883 14.2516 11.9983 14.2516C12.4083 14.2516 12.7483 14.5916 12.7483 15.0016V18.0016Z"
            fill="currentColor"
          />
        </svg>
      );
    case "messages":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.19 6H6.79C6.53 6 6.28 6.01 6.04 6.04C3.35 6.27 2 7.86 2 10.79V14.79C2 18.79 3.6 19.58 6.79 19.58H7.19C7.41 19.58 7.7 19.73 7.83 19.9L9.03 21.5C9.56 22.21 10.42 22.21 10.95 21.5L12.15 19.9C12.3 19.7 12.54 19.58 12.79 19.58H13.19C16.12 19.58 17.71 18.24 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6ZM6.5 14C5.94 14 5.5 13.55 5.5 13C5.5 12.45 5.95 12 6.5 12C7.05 12 7.5 12.45 7.5 13C7.5 13.55 7.05 14 6.5 14ZM9.99 14C9.43 14 8.99 13.55 8.99 13C8.99 12.45 9.44 12 9.99 12C10.54 12 10.99 12.45 10.99 13C10.99 13.55 10.55 14 9.99 14ZM13.49 14C12.93 14 12.49 13.55 12.49 13C12.49 12.45 12.94 12 13.49 12C14.04 12 14.49 12.45 14.49 13C14.49 13.55 14.04 14 13.49 14Z"
            fill="currentColor"
          />
          <path
            d="M21.9802 6.79V10.79C21.9802 12.79 21.3602 14.15 20.1202 14.9C19.8202 15.08 19.4702 14.84 19.4702 14.49L19.4802 10.79C19.4802 6.79 17.1902 4.5 13.1902 4.5L7.10025 4.51C6.75025 4.51 6.51025 4.16 6.69025 3.86C7.44025 2.62 8.80025 2 10.7902 2H17.1902C20.3802 2 21.9802 3.6 21.9802 6.79Z"
            fill="currentColor"
          />
        </svg>
      );
    case "setting":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.1 9.2214C18.29 9.2214 17.55 7.9414 18.45 6.3714C18.97 5.4614 18.66 4.3014 17.75 3.7814L16.02 2.7914C15.23 2.3214 14.21 2.6014 13.74 3.3914L13.63 3.5814C12.73 5.1514 11.25 5.1514 10.34 3.5814L10.23 3.3914C9.78 2.6014 8.76 2.3214 7.97 2.7914L6.24 3.7814C5.33 4.3014 5.02 5.4714 5.54 6.3814C6.45 7.9414 5.71 9.2214 3.9 9.2214C2.86 9.2214 2 10.0714 2 11.1214V12.8814C2 13.9214 2.85 14.7814 3.9 14.7814C5.71 14.7814 6.45 16.0614 5.54 17.6314C5.02 18.5414 5.33 19.7014 6.24 20.2214L7.97 21.2114C8.76 21.6814 9.78 21.4014 10.25 20.6114L10.36 20.4214C11.26 18.8514 12.74 18.8514 13.65 20.4214L13.76 20.6114C14.23 21.4014 15.25 21.6814 16.04 21.2114L17.77 20.2214C18.68 19.7014 18.99 18.5314 18.47 17.6314C17.56 16.0614 18.3 14.7814 20.11 14.7814C21.15 14.7814 22.01 13.9314 22.01 12.8814V11.1214C22 10.0814 21.15 9.2214 20.1 9.2214ZM12 15.2514C10.21 15.2514 8.75 13.7914 8.75 12.0014C8.75 10.2114 10.21 8.7514 12 8.7514C13.79 8.7514 15.25 10.2114 15.25 12.0014C15.25 13.7914 13.79 15.2514 12 15.2514Z"
            fill="currentColor"
          />
        </svg>
      );
    case "user":
      return (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19 3.29 20.93 5.56 21.66C6.22 21.89 6.98 22 7.81 22H16.19C17.02 22 17.78 21.89 18.44 21.66C20.71 20.93 22 19 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM20.5 16.19C20.5 18.33 19.66 19.68 17.97 20.24C17 18.33 14.7 16.97 12 16.97C9.3 16.97 7.01 18.32 6.03 20.24H6.02C4.35 19.7 3.5 18.34 3.5 16.2V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V16.19Z"
            fill="currentColor"
          />
          <path
            d="M12.0019 8C10.0219 8 8.42188 9.6 8.42188 11.58C8.42188 13.56 10.0219 15.17 12.0019 15.17C13.9819 15.17 15.5819 13.56 15.5819 11.58C15.5819 9.6 13.9819 8 12.0019 8Z"
            fill="currentColor"
          />
        </svg>
      );
    case "queries":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 2.42969H7C4 2.42969 2 4.42969 2 7.42969V13.4297C2 16.4297 4 18.4297 7 18.4297V20.5597C7 21.3597 7.89 21.8397 8.55 21.3897L13 18.4297H17C20 18.4297 22 16.4297 22 13.4297V7.42969C22 4.42969 20 2.42969 17 2.42969ZM12 14.5997C11.58 14.5997 11.25 14.2597 11.25 13.8497C11.25 13.4397 11.58 13.0997 12 13.0997C12.42 13.0997 12.75 13.4397 12.75 13.8497C12.75 14.2597 12.42 14.5997 12 14.5997ZM13.26 10.4497C12.87 10.7097 12.75 10.8797 12.75 11.1597V11.3697C12.75 11.7797 12.41 12.1197 12 12.1197C11.59 12.1197 11.25 11.7797 11.25 11.3697V11.1597C11.25 9.99969 12.1 9.42969 12.42 9.20969C12.79 8.95969 12.91 8.78969 12.91 8.52969C12.91 8.02969 12.5 7.61969 12 7.61969C11.5 7.61969 11.09 8.02969 11.09 8.52969C11.09 8.93969 10.75 9.27969 10.34 9.27969C9.93 9.27969 9.59 8.93969 9.59 8.52969C9.59 7.19969 10.67 6.11969 12 6.11969C13.33 6.11969 14.41 7.19969 14.41 8.52969C14.41 9.66969 13.57 10.2397 13.26 10.4497Z"
            fill="currentColor"
          />
        </svg>
      );
    case "logout":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
            fill="currentColor"
          />
          <path
            d="M4.56141 11.2498L6.63141 9.17984C6.78141 9.02984 6.85141 8.83984 6.85141 8.64984C6.85141 8.45984 6.78141 8.25984 6.63141 8.11984C6.34141 7.82984 5.86141 7.82984 5.57141 8.11984L2.22141 11.4698C1.93141 11.7598 1.93141 12.2398 2.22141 12.5298L5.57141 15.8798C5.86141 16.1698 6.34141 16.1698 6.63141 15.8798C6.92141 15.5898 6.92141 15.1098 6.63141 14.8198L4.56141 12.7498H9.00141V11.2498H4.56141Z"
            fill="currentColor"
          />
        </svg>
      );
    case "campaign":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="CurrentColor"
        >
          <path
            d="M12.1425 1.50024H5.8575C3.1275 1.50024 1.5 3.12774 1.5 5.85774V12.1352C1.5 14.8727 3.1275 16.5002 5.8575 16.5002H12.135C14.865 16.5002 16.4925 14.8727 16.4925 12.1427V5.85774C16.5 3.12774 14.8725 1.50024 12.1425 1.50024ZM6.8325 12.6752C6.8325 12.8852 6.6675 13.0502 6.4575 13.0502H4.365C4.155 13.0502 3.99 12.8852 3.99 12.6752V9.21024C3.99 8.73774 4.3725 8.35524 4.845 8.35524H6.4575C6.6675 8.35524 6.8325 8.52024 6.8325 8.73024V12.6752ZM10.4175 12.6752C10.4175 12.8852 10.2525 13.0502 10.0425 13.0502H7.95C7.74 13.0502 7.575 12.8852 7.575 12.6752V5.80524C7.575 5.33274 7.9575 4.95024 8.43 4.95024H9.57C10.0425 4.95024 10.425 5.33274 10.425 5.80524V12.6752H10.4175ZM14.01 12.6752C14.01 12.8852 13.845 13.0502 13.635 13.0502H11.5425C11.3325 13.0502 11.1675 12.8852 11.1675 12.6752V10.0127C11.1675 9.80274 11.3325 9.63774 11.5425 9.63774H13.155C13.6275 9.63774 14.01 10.0202 14.01 10.4927V12.6752Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      console.error(`Icon "${iconName}" does not exist.`);
      return null;
  }
};
const SvgIcon = ({ name, className, style, isSelected, color }) => {
  const Icon = getSvgIcon(name);

  if (!Icon) {
    console.error(`SVG "${name}" does not exist.`);
    return null;
  }

  return (
    <span
      role="img"
      aria-label="comment"
      className="anticon anticon-comment icon ant-menu-item-icon"
    >
      {Icon}
    </span>
  );
};

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
  color: PropTypes.string,
};

SvgIcon.defaultProps = {
  className: "",
  style: {},
  isSelected: false,
  color: "transparent",
};

export default SvgIcon;
