import React, { useEffect, useState, useRef, useCallback } from "react";
import face5 from "../images/history/profile.png";
import $ from "jquery";
import "datatables.net";
import { Card, Col, Row, Table, Select, Button, Avatar, Radio, Typography,Tooltip } from "antd";
import userSettingsData from "../../data/userSettingsData";
import modulesData from "../../data/ModuleData";

const { Option } = Select;
const { Title } = Typography;

const Setting = () => {
  const [totalPage, setTotalPage] = useState(10);
  const [value, setValue] = useState(1);
  const [isLinked, setIsLinked] = useState(false);
  const tableRef = useRef(null);
  const getProductIdFromLocalStorage = useCallback(() => {
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    return selectedProduct ? selectedProduct.id : 1;
  }, []);
  const productId = getProductIdFromLocalStorage();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = localStorage.getItem("userToken");

  const handleTotalPageChange = (val) => {
    setTotalPage(val);
  };

  const changeAccount = () => {
    setIsLinked((prevState) => !prevState);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const tabItem = [
    {
      id: 0,
      title: "Profile",
    },
    {
      id: 1,
      title: "Modules",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3,
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: {
        compare: (a, b) => a.role.localeCompare(b.role),
        multiple: 2,
      },
    },
  ];

  useEffect(() => {
    if (!tableRef.current) return;

    setLoading(true);
    try {
      const table = $(tableRef.current).DataTable({
        dom: "<'overflow-auto't><'footerContainer overflow-auto justify-content-end'<'dt-layout-cell dt-end'p>>",
        language: {
          search: '<div class="customSearchSvg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.25 15C11.9779 15 15 11.9779 15 8.25C15 4.52208 11.9779 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.9779 4.52208 15 8.25 15Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.1973 15.5174C14.5948 16.7174 15.5023 16.8374 16.1998 15.7874C16.8373 14.8274 16.4173 14.0399 15.2623 14.0399C14.4073 14.0324 13.9273 14.6999 14.1973 15.5174Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          searchPlaceholder: "Search",
        },
        data: modulesData,
        order: [0, "desc"],
        columns: [
          { data: "Module", title: "Module" },
          { data: "Description", title: "Description" },
          {
            data: "Status", title: "Status",
            render: function (data) {
              switch (data) {
                case "Active":
                  return `<span class="btn success">${data}</span>`;
                case "Inactive":
                  return `<span class="btn danger">${data}</span>`;
                default:
                  return `<span class="btn warning">${data}</span>`;
              }
            }
          },
          {
            data: "Info", title: "Info",
             render: function (data) {
              return (data !== "") ? `<span class="customtooltip" style="cursor:pointer" ><svg class="customtooltip" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
              </svg><span class="customtooltiptext">${data}</span></span>` : "";
            }
          },
        ],
      });

    } catch (error) {
      setIsError(error.message);
    } finally {
      setLoading(false);
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy(true);
      }
    };
  }, [productId,value]);

  return (
    <div className="layout-content settingsPage">
      <div className="tabs-container">
        <Radio.Group onChange={onChange} value={value} className="custom-tabs">
          {tabItem.map((item, index) => (
            <Radio
              value={item.id}
              className={value === item.id ? "tab selected" : "tab"}
              key={index}
            >
              {item.title}
            </Radio>
          ))}
        </Radio.Group>
        <div className="tab-content">
          {value === 0 && (
            <div>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24} xl={24} lg={24} xxl={24} span={24} className="zindex">
                  <Row gutter={[24, 0]}>
                    <Card className="mb-24 w-auto">
                      <Row gutter={[24, 0]} className="item-body">
                        <Avatar src={face5} alt="Face 5" className="icon-box card-image" />
                        <Col>
                          <h4 className="h4 mb-0">John Doe</h4>
                          <Col className="card-footer">
                            <span className="admin">Super Admin</span>
                            <Button className="text-end" type="link">
                              Change Password
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Row>
              <Card bordered={false} className="header-solid mb-24" title={<Title level={4}> Users </Title>}>
                <div className="px-25">
                  <Select
                    defaultValue={totalPage}
                    onChange={handleTotalPageChange}
                    size="middle"
                    style={{ width: "70px" }}
                  >
                    <Option value="5">5</Option>
                    <Option value="10">10</Option>
                    <Option value="15">15</Option>
                    <Option value="20">20</Option>
                    <Option value="25">25</Option>
                  </Select>
                  <label className="ml-10">entries per page</label>
                </div>
                <div className="ant-table-body">
                  <Table
                    className="mt-20 text-center"
                    columns={columns}
                    dataSource={userSettingsData}
                    tableLayout="auto"
                    onChange={onchange}
                    scroll={{ x: 500 }}
                    pagination={{
                      pageSize: totalPage,
                    }}
                  />
                </div>
              </Card>
            </div>
          )}
          {value === 1 && (
            <Col xxl={24} xl={24} lg={24} md={24}>
              <Card>
                <div className="customTable p-0 m-0">
                  <table
                    ref={tableRef}
                    className="table table-responsive p-0"
                    style={{ width: "100%" }}
                  >
                    <thead className="ant-table-thead">
                      <tr className="ant-table-row">
                        <th className="ant-table-cell w-30">Module</th>
                        <th className="ant-table-cell w-50">Description</th>
                        <th className="ant-table-cell w-30">Status</th>
                        <th className="ant-table-cell w-30">Info</th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody"></tbody>
                  </table>
                </div>
                </Card>
              </Col>
          )}
        </div>
      </div>
    </div>
  );
};

export default Setting;
