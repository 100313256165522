import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Main from "./component/layout/main";
import SignIn from "./component/pages/SignIn";
import ProtectedRoute from "./utils/protectedRoute";
import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./responsive.css";
import "./App.css";
import moment from "moment";
import { AuthProvider } from "./context";

function App() {
  const [date, setDate] = useState([moment().subtract(1, "month"), moment()]);
  return (
      <AuthProvider>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/signin" component={SignIn} />
              <ProtectedRoute
                path="/:id"
                component={Main}
                date={date}
                setDate={setDate}
              />
              <Redirect
                to={{
                  pathname: "/signin",
                }}
              />
            </Switch>
          </Router>
        </div>
      </AuthProvider>
  );
}

export default App;
