import httpService from "../utils/httpService";

class ConversationalService {
  getConversationalData = async (productId, bodyParams) => {
    if (!productId) {
      throw new Error('No productId found in localStorage');
    }
    const endpoint = `${productId}/conversation-analysis-data`;
    return await httpService.post(endpoint, bodyParams);
  };
}

const conversationalService = new ConversationalService();
export default conversationalService;
